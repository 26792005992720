/*

	Helper mixin extends a view component with some useful methods

	2019-09-28	init

*/
import { EventBus } from '@/event-bus.js'

export default {
	metaInfo() {
		return {
			title: this.title,
			titleTemplate: this.titleTemplate,
			meta: [],
			link: [],
		}
	},
	watch: {
		viewIsReady ( to, from ) {
			if( to ) {
				EventBus.$emit('ViewEvent', {
					fromComponent : this.$options.name,
					eventName     : 'ready',
					//vibrantColor  : this.vibrantColor, // wird nur vom LuProductView gesetzt
					viewBackgroundColor  : this.viewBackgroundColor,
					viewTextColor  : this.viewTextColor,
				})
			}
		},
	},
	data () {
		return {
			title : '',
			titleTemplate : (titleChunk) => {
				// re-replace ampersand '&#038;' back to '&'' in title
				const cleanTitleChunk = titleChunk.replace('&#038;', '&')
				// If undefined or blank then we don't need the hyphen
				return cleanTitleChunk ? `${cleanTitleChunk} – Genussmarken` : 'Genussmarken';
			},

			viewIsReady  : false,
			viewBackgroundColor : null,
			viewTextColor : null,
			//vibrantColor : false, // wird nur vom LuProductView gesetzt
		}
	},
	computed: {},
	methods: {},
	beforeCreate() {},
	created() {},
	beforeMount() {
		EventBus.$emit('ViewEvent', {
			fromComponent : this.$options.name,
			eventName     : 'beforeMount',
		})
	},
	mounted() {
		EventBus.$emit('ViewEvent', {
			fromComponent : this.$options.name,
			eventName     : 'mounted',
		})
	},
	beforeDestroy(){
		EventBus.$emit('ViewEvent', {
			fromComponent : this.$options.name,
			eventName     : 'beforeDestroy',
		})
	},
}
