<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmSlideOutFooter></GmSlideOutFooter>
	
	2019-06-08	init

-->

<template>
	<div class="GmSlideOutFooter" :class="elmClasses">
        <!-- 
			dieses Bild ist unsichtbar und dient nur dafür 
			die dimensions der componente zu erzeugen 
		-->
		<GmImage
            class="GmSlideOutFooter__layoutHelperGmImage"
			:image="media"
            :fullBleed="true"
        ></GmImage>
		
		<!--
			in diesem wrapper befindet sich das sichtbare bild,
			das mit position:fixed versehen ist.
			die bessere lösung wäre natürlich position:sticky;bottom:0; 
			gewesen, doch leider bringt safari dann die z-indexes durcheinander
			und das bild ploppt beim scrollen immermal über den body unter dem
			es eigentlich liegen sollte. safari is the new ie :(
		-->
		<div class="GmSlideOutFooter__imageWrapper">
			<GmImage
				:image="media"
				:fullBleed="true"
			></GmImage>
		</div>
        
		<!--
        <pre>{{media}}</pre>
        -->
    </div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import GmImage from '@/components/GmImage.vue'

	export default {
		name: 'GmSlideOutFooter',
		components: {
            GmImage,
        },
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
            media(){    
                return this._.get( this.app, 'acfOptions.slideoutImage' )
            },
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";
	
    .GmSlideOutFooter {
        position: relative;
        max-height: 95vh; // edge case: wenn das bild höher als der viewport ist kommt es sonst zu einem unschönen scrollverhalten
        overflow: hidden;
		margin-top: -2px; // prevent 1px render gap on top
		
		//pointer-events: none;

		&__layoutHelperGmImage {
			visibility: hidden;
		}
		&__imageWrapper {
			//background-color: fade(red, 75);
			//padding: 1em;
			position: fixed;
			bottom: 0; left: 0; right: 0;
		}
    }
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
