<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhVideo
			:videoObject="{}"
		></MhVideo>

	2019-10-05	init

-->

<template>
	<div class="MhVideo" :class="elmClasses">
		<div class="MhVideo__inner" :style="innerStyles">
			<video
				class="MhVideo__videoElm"
				:src="videoSrc"
				:autoplay="autoplay"
				loop
				XXXcontrols
				muted
				playsinline
				ref="videoElm"
			></video>
		</div>

		<!--
		<pre data-name="videoObject">{{videoObject}}</pre>
	 	-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'MhVideo',
		components: {},
		mixins: [],
		props: {
			videoObject: {
				type     : [Object, Boolean],
				default  : false,
				required : false,
			},
			autoplay: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			innerStyles(){
				const paddingBottom = this.videoObject ? ( this.videoObject.height / this.videoObject.width ) * 100 : 0;

				return {
					paddingBottom : paddingBottom + '%',
					//color : this.textColor,
				}
			},
			videoSrc(){
				return this.videoObject ? this.videoObject.url : null
			}
		},
		methods: {},
		created() {},
		mounted() {
			if( this.videoObject ){
				const videoElm = this.$refs.videoElm

				/*
				console.log();
				console.log('MhVideo');
				console.log('	videoElm', videoElm);
				*/
			}
		},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	.MhVideo {
		position: relative;

		&__inner {
			position: relative;
			width: 100%;
			background-color: fade(black, 10); // nicer not-loaded-yet look
		}
		&__videoElm {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			pointer-events: none;

			&:focus {
				outline: none;
			}
		}
	}

	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
