<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmMainFooter
			firstText="<strong>Grabowski Böll <br />Genussmarken</strong>"
			secondText="<a href="">Leistungen</a><br /><a href="">Referenzen</a><br /><a href="">Kontakt</a><br /><a href="">Datenschutz</a><br />"
			thirdText=""
			fourthText="<a href="">in@grabowskt-boell.de</a><br />0341 60 43 9287<br /><br /><a href="">Instagram</a><br />"
		></GmMainFooter>
	
	2019-06-08	init

-->

<template>
	<div class="GmMainFooter contentModule">
		<div class="contentModule__inner grid grid--GmMainFooter color color--almostWhite">
			<div class="GmMainFooter__col GmMainFooter__col--1">
				<div class="font font--mono" v-html="firstText"></div>
			</div>
			<div class="GmMainFooter__col GmMainFooter__col--2">
				<div class="font font--mono" v-html="secondText"></div>
			</div>
			<!-- 
			<div class="GmMainFooter__col GmMainFooter__col--3">
				<div class="font font--mono" v-html="thirdText"></div>
			</div>
			-->
			<div class="GmMainFooter__col GmMainFooter__col--4">
				<div class="font font--mono" v-html="fourthText"></div>
			</div>
			
			<div class="GmMainFooter__overflowHelper"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmMainFooter',
		components: {},
		mixins: [],
		props: {
			firstText: [String],
			secondText: [String],
			thirdText: [String],
			fourthText: [String],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";

	// layout: alle deklarations bzgl. position und dimension
	.GmMainFooter {}
	
	// appearance: alle deklarations außer position und dimension
	.GmMainFooter {
		&__col--2,		// handle links
		&__col--3,
		&__col--4 {
			a { opacity: 0.5; }
		}
		
		// die telefonnummer soll ebenfalls hellgrau sein, hat aber keinen link
		&__col--4 p:nth-child(2){
			opacity: 0.5;
		}
		
		a {
			transition: opacity 0.1s ease;
			color: inherit;
			text-decoration: none;
		}
		a:hover {
			opacity: 1;
		}
	}
</style>
