<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmSpacer
			lines="2"
		></GmSpacer>
	
	2019-06-24	init

-->

<template>
	<div class="GmSpacer contentModule" :class="elmClasses">
		<div class="contentModule__inner">
			<div class="GmSpacer__row font font--sizeDefault" v-for="(row, index) in rows" :key="index"><br /></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmSpacer',
		components: {},
		mixins: [],
		props: {
			lines: [String, Number],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			rows(){
				return parseInt( this.lines )
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	
	// layout: alle deklarations bzgl. position und dimension
	.GmSpacer {
		&__row {
			//height: @colGap;
		}
	}
	
	// appearance: alle deklarations außer position und dimension
	.GmSpacer {
	}
</style>
