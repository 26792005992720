<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<Gm404></Gm404>
	
	2019-06-29	init

-->

<template>
	<div class="Gm404" :class="elmClasses">
		
		<GmSpacer
			lines="3"
		></GmSpacer>
		
		<GmHeadline
			title="Fehler 404"
			fontSize="default"
			linkUrl=""
			linkText=""
		></GmHeadline>
		
		<GmText
			font="sans"
			text="Der Inhalt wurde leider nicht gefunden."
		></GmText>			
		
		<GmSpacer
			lines="3"
		></GmSpacer>
		
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import GmHeadline from '@/components/GmHeadline.vue'
	import GmText from '@/components/GmText.vue'
	import GmSpacer from '@/components/GmSpacer.vue'

	export default {
		name: 'Gm404',
		components: {
			GmHeadline,
			GmText,
			GmSpacer,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []
				
				return classes
			},
			currentUrl(){
				return window.location
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less" scoped>
	@import "../less/vars.less";
	@import "../less/atoms.less";
	
	.Gm404 {}
</style>
