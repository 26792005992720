<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmTestimonial
			title="Leistungen für dieses Projekt"
			servicesText="Kernidee<br />Markenwerte<br />Naming<br />Flaschenausstattung<br />Corporate Design<br />"
			testimonialImage=""
			testimonialQuote="Die Zusammenarbeit mit Grabowski Böll war schön und hat Spaß gemacht. Und das Beste: Wir verkaufen nun doppelt so viele Flaschen wie vorher."
			testimonialName="Hans-Albrecht Zieger"
			testimonialFunction="Geschäftsführer"
		></GmTestimonial>
	
	2019-06-08	init

-->

<template>
	<div class="GmTestimonial contentModule">
		<div class="contentModule__inner grid grid--GmTestimonial">
			<h2 class="GmTestimonial__title font font--sizeSmall font--mono font--bold font--uppercase color color--almostWhite" v-html="title"></h2>
				
			<div class="GmTestimonial__servicesWrapper bgColor bgColor--almostWhite color color--darkGreen">
				<div class="GmTestimonial__services font font--sizeMediumSans" v-html="servicesText"></div>
			</div>
			
			<div class="GmTestimonial__testimonialWrapper color color--almostWhite border border--almostWhite" v-if="testimonialQuote">
				<div class="GmTestimonial__quote font font--serif font--sizeMedium" >
					<MhImage 
						class="GmTestimonial__quoteImage"
						:imageObject="testimonialImage"
					></MhImage>				
					<span v-html="testimonialQuote"></span>
				</div>
				<div class="GmTestimonial__quoteFooter font font--mono font--sizeSmall font--uppercase">
					<strong v-html="testimonialName"></strong><br />
					<span v-html="testimonialFunction"></span>
				</div>
			</div>
		</div>	
	</div>	
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'GmTestimonial',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			title: [String],
			servicesText: [String],
			testimonialImage: [Object, Boolean],
			testimonialQuote: [String],
			testimonialName: [String],
			testimonialFunction: [String],
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	
	// layout: alle deklarations bzgl. position und dimension
	.GmTestimonial {
		
		&__title {
			padding-left: 1.75rem;
			padding-bottom: 1rem;
			
			@media @mediaQuery__md { 
				padding-left: 1rem;
				
				padding-bottom: 0.5rem; // 
			}
			@media @mediaQuery__sm { 
				padding-bottom: 0.5rem;
			}
		}
		
		&__servicesWrapper {
			padding: 1.25rem 1.75rem;
			border-radius: 0.333rem;
			
			@media @mediaQuery__md { 
				padding: 0.6rem 1em;
			}
		}
		&__testimonialWrapper {
			border-radius: 0.333rem;
			overflow: hidden; // damit der border-radius auch das bild beschneidet			
		}
		
		&__quote {
			padding: 1rem;
			padding-left: 1.6rem;
			
			@media @mediaQuery__md { 
				padding: 0.75rem;
				padding-left: 1rem;
				
				padding: 1rem;
				padding-top: 0.75rem;
			}
			@media @mediaQuery__sm { 
				padding: 1rem;
				padding-top: 0.75rem;
			}
		}
		&__quoteImage {
			//outline: 1px solid red;
			display: inline-block;
			float: left;
			
			width: 121px;
			
			margin-top: -1.1rem;
			margin-left: -1.75rem;
			margin-right: 1.6rem;	
			
			@media @mediaQuery__md { 
				margin-top: -0.75rem;
				margin-left: -1rem;
				margin-right: 1.1rem;
				
				width: 113px;
				
				margin-left: -1rem;
				margin-top: -0.75rem;
				margin-right: 1rem;
								
				width: 95px;
			}
			@media @mediaQuery__sm { 
				margin-left: -1rem;
				margin-top: -0.75rem;
				margin-right: 1rem;
								
				width: 95px;
			}
		}
		&__quoteFooter {
			padding: 1rem 1.7rem;			
			padding-bottom: 1.2rem;
			
			@media @mediaQuery__md { 
				padding: 0.75rem;
				padding-left: 1.1rem;
				
				padding: 0.75rem;
				padding-left: 1rem;
				padding-top: 0.25rem;
			}
			@media @mediaQuery__sm { 
				padding: 0.75rem;
				padding-left: 1rem;
				padding-top: 0.25rem;
			}
		}
	}
	
	// appearance: alle deklarations außer position und dimension
	.GmTestimonial {}
</style>
