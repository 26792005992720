<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<GmSection
			:section="{}"
			:number="0"
		></GmSection>

	2019-06-08	init

-->

<template>
	<div class="GmSection"
		 :class="elmClasses"
		 :style="elmStyles"
		 :data-index="index">

		<div class="GmSection__anchor"
			 :class="'GmSection__anchor--' + index"
			 :data-index="index"></div>

		<div class="GmSection__inner maxWidth XXmaxWidth--sectionInner">
			<slot></slot>
		</div>

		<div class="GmSection__visibleHelper"
			 v-observe-visibility="sectionVisibilityChanged"
			 v-if="!includesGmMainHeader && !includesGmAnchorNav"></div>

		<div class="GmSection__changeAnchorNavBgSentinel"
			 :data-index="index"
			 v-observe-visibility="changeAnchorNavBgSentinelObserver"
			 v-if="index && !includesGmMainHeader && !includesGmAnchorNav"></div>

		<div class="GmSection__changeAnchorNavBgDebug" v-if="includesGmAnchorNav">
			{{scrollDirection}}<br />
			<div v-for="(visibleSection, index) in visibleSections"
				:key="index"
				:style="{ backgroundColor : visibleSection.bgColor }">&nbsp;&nbsp;&nbsp;</div>
		</div>

		<div class="GmSection__debug" v-if="scrollDirection">{{scrollDirection}}</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmSection',
		components: {},
		mixins: [],
		props: {
			/*
			section: [Object],
			index: [Number],
			postUseStickyHeader: [Boolean],
			*/
			section: {
				type     : [Object],
				default  : ()=>{ return {} },
				required : false,
			},
			index: {
				type     : [Number],
				default  : undefined,
				required : false,
			},
			postUseStickyHeader: {
				type     : [Boolean],
				default  : undefined,
				required : false,
			},
		},
		data() {
			return {
				doEmitVisibilityChanged : false,
				checkSectionVisibleParts : false,
				isSticky : false,
				isOffscreen : false,
				includesGmMainHeader : false,
				includesGmAnchorNav : false,
				includesGmProcessColumns : false,
				includesGmFullscreenMedia : false,
				visibleParts : [],

				// for scrollHandler
				prevScrollTop : null,
				currentScrollTop : null,
				scrollDirection : null,
				inView : null,

				// for changeAnchorNavBg
				visibleSections : [],
				debugVisibleSections : false,
			}
		},
		watch: {
			scrollDirection( to, from ) { // set sticky on scrollUp
				if( to === '↑' && this.postUseStickyHeader ) this.isSticky = true
				if( to === '↓' && this.postUseStickyHeader ) this.isSticky = false
			}
		},
		computed: {
			elmClasses() { // 2020-04-25 sticky-class disabled
				let classes = []

				//if( this.isSticky ) classes.push( 'sticky' )
				if( this.isOffscreen ) classes.push( 'GmSection--offscreen' )
				if( this.includesGmMainHeader ) classes.push( 'GmSection--includesGmMainHeader' )
				if( this.includesGmAnchorNav ) classes.push( 'GmSection--includesGmAnchorNav' )
				if( this.includesGmProcessColumns ) classes.push( 'GmSection--includesGmProcessColumns' )
				if( this.includesGmFullscreenMedia ) classes.push( 'GmSection--includesGmFullscreenMedia' )
				if( this.visibleParts.length ) classes.push( 'GmSection--visibleParts-' + this.visibleParts.join('') )

				return classes
			},
			elmStyles(){
				if( this.includesGmAnchorNav ){
					return {
						backgroundColor : this.visibleSections.length ? this.visibleSections[0].bgColor : this.section.bgColor
					}
				}else{
					return {
						backgroundColor : this.section ? this.section.bgColor : ''
					}
				}
			},
		},
		methods: {
			logVisibleParts(){
				const visibleParts = this.visibleParts

				//console.log( visibleParts );
			},
			sectionVisibilityChanged( isVisible, entry ){ // see: https://github.com/Akryum/vue-observe-visibility
				if( this.doEmitVisibilityChanged ){
					EventBus.$emit('GmSection: visibilityChanged', {
						elmIndex : this.index,
						isVisible : isVisible,
						entry : entry,
					})
				}
			},
			changeAnchorNavBgSentinelObserver( isVisible, entry ){
				/*
				console.group('changeAnchorNavBgSentinelObserver()')
				console.log('index:     ', this.index)
				console.log('isVisible: ', isVisible)
				console.log('bgColor:   ', this.section.bgColor)
				//console.log('scrollDirection:   ', this.scrollDirection)
				console.groupEnd()
				*/

				EventBus.$emit('GmSection: anchorNavSentinelVisibilityChanged', {
					index     : this.index,
					isVisible : isVisible,
					bgColor   : this.section ? this.section.bgColor : false,
					//scrollDirection : this.scrollDirection,
				})
			},
			getBodyScrollTop(){ // iOS works with document.scrollingElement
				const el = document.scrollingElement || document.documentElement
				return el.scrollTop
			},
			scrollHandler( e = null ){ // informs about the scrollDirection
				//const currentScrollTop = document.documentElement.scrollTop
				const currentScrollTop = this.getBodyScrollTop()
				const scrollDelta      = currentScrollTop - this.prevScrollTop
				const elmBounding      = this.$el.getBoundingClientRect()
				const elmHeight        = elmBounding.height
				const elmTop           = elmBounding.top
				const inView           = ( elmHeight + elmTop ) > 0 ? true : false
				let   scrollDirection  = null
				const debugLog         = false

				// set direction but not on mount where no scroll had happend
				if( this.prevScrollTop !== null ) {
					scrollDirection  = scrollDelta > 0 ? '↓' : '↑'
				}

				if( debugLog ){
					console.group('scrollHandler()')
					console.log('currentScrollTop', currentScrollTop)
					console.log('scrollDelta', scrollDelta)
					console.log('scrollDirection', scrollDirection)
					console.log('inView', inView)
					console.groupEnd()
				}

				this.prevScrollTop = currentScrollTop
				this.currentScrollTop = currentScrollTop
				this.scrollDirection = scrollDirection
				this.inView = inView
			},
		},
		created() {},
		mounted() {
			this.doEmitVisibilityChanged = true
			this.checkSectionVisibleParts = true

			// add handler for: check scrolling to toggle sticky
			if( this.includesGmMainHeader ){
				window.addEventListener('scroll', this.scrollHandler)
			}

			// if this is the section with the GmAnchorNav,
			// it will listen to sentinel visibility changes
			if( this.includesGmAnchorNav ){
				window.addEventListener('scroll', this.scrollHandler) // needed to set scrollDirection

				EventBus.$on('GmSection: anchorNavSentinelVisibilityChanged', payload => {
					const index           = payload.index
					const isVisible       = payload.isVisible
					const bgColor         = payload.bgColor
					const scrollDirection = this.scrollDirection

					/*
					console.group('EventBus.$on(GmSection: anchorNavSentinelVisibilityChanged)')
					console.log('index:           ', index)
					console.log('isVisible:       ', isVisible)
					console.log('bgColor:         ', bgColor)
					console.log('scrollDirection: ', scrollDirection)
					console.groupEnd()
					*/

					// add section
					if( isVisible ){
						// prepend if upscroll
						if( scrollDirection == '↑'){
							this.visibleSections.unshift({
								index : index,
								bgColor : bgColor,
							})
						}
						// append if downscroll
						else{
							this.visibleSections.push({
								index : index,
								bgColor : bgColor,
							})
						}
					}
					// remove section
					else{
						const newVisibleSections = []

						this._.forEach( this.visibleSections, visibleSection => {
							if( visibleSection.index != index ){
								newVisibleSections.push( visibleSection )
							}
						})

						this.visibleSections = newVisibleSections
					}
				})
			}
		},
		destroyed() {
			// remove handle for: check scrolling to toggle sticky
			if( this.includesGmMainHeader ){
				window.removeEventListener('scroll', this.scrollHandler)
			}
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/atoms.less";

	/*
	body {
		min-height: 100vh !important;
		min-height: -webkit-fill-available !important;
	}
	html {
		height: -webkit-fill-available !important;
	}
	*/

	// just the debugHelper for the current scroll direction
	.GmSection__debug {
		//[showBorders4] & { display: block; }

		position: fixed;
		bottom: 0; left: 0;
		z-index: 100;
		background-color: pink;
		color: black;
		font-size: 10vw;
		line-height: 1em;
		padding: 0.25em;
		overflow: hidden;
		display: none;
	}

	// layout
	.GmSection {
		@anchorOffset : -4rem;

		[showBorders1] & {
			outline: 1px solid cyan;

			.label( 'section ' attr(data-index), cyan, black )
		}

		position: relative;
		display: flex;
		justify-content: center;
		//padding-top: @section__paddingTop;
		//padding-bottom: @section__paddingBottom;
		transition: background-color 0.1s ease;
		background-color: var(--appBody__background);

		// Für den GmSlideOutFooter müssen alle Seiteninhalte über dem GmSlideOutFooter liegen
		z-index: 1;


		// 2019-10-08 ansonsten würden die visibleHelper z.T. herausschauen und am Fuße der
		// Seite einen weißen space erzeugen
		// .app__nav ist ausgenommen, da hier das menü drin liegt welches ansonsten,
		// zumindestens unter iOS Safari, abgeschnitten wäre
		// 2019-12-02  GmSection--includesGmProcessColumns ist ebenfalls ausgenommen,
		// weil es hier wichtig ist, dass die sentinels vorher sichtbar sind
		&:not(.app__nav):not(.GmSection--includesGmProcessColumns) { overflow: hidden; }

		// im den ios 100vh bug zu lösen wird hier mit
		// "-webkit-fill-available" gearbeitet
		// siehe: https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/
		&--includesGmFullscreenMedia {
			height: 100vh;
  			max-height: -webkit-fill-available;
			//opacity: 0.5;
			//overflow: hidden;
		}


		&__anchor { // der anchor dient als sprungziel für die GmAnchorNav und ist nach oben versetzt
			//[showBorders5] & { visibility: visible;	}
			background-color: fade( red, 40 ); min-height: 1em;
			color: red; text-align: right;
			z-index: 20;

			position: absolute;
			top: @anchorOffset; left: 0; right: 0;
			height: -@anchorOffset;
			&:before { content: "GmSection__anchor for " attr(data-index); }
			visibility: hidden;
			pointer-events: none;

		}
		&__inner {
			position: relative;
			width: 100%;
			padding-top: @section__paddingTop;
			padding-bottom: @section__paddingBottom;

			//display: flex;
    		//flex-direction: column;
    		//align-items: center;
		}

		// helper elements for visibility detection via mutation observer
		// to mark links in GmAnchorNav
		&__visibleHelper {
			visibility: hidden;
			background-color: fade( red, 20 );

			z-index: 20;

			position: absolute;
			top: 66vh;
			bottom: 5rem;
			left: 0.5em;
			min-width: 1em;
			pointer-events: none;

			// dieser helper steuert das umschalten der unterstreichung für GmAnchorNav
			top: calc( 100vh - 65px ); bottom: 0rem;
			@media @mediaQuery__md { top: calc( 100vh - 65px ); }
			@media @mediaQuery__sm { top: calc( 100vh - 55px ); }

			&:before { content: "&__visibleHelper"; }
			[showBorders5] & { visibility: visible; }
		}

		// helper elements for visibility detection via mutation observer
		// to change GmAnchorNav background-color
		&__changeAnchorNavBgSentinel {
			visibility: hidden;
			background-color: yellow;

			position: absolute;
			right: 0.5em;

			// der helper hat die größe der kompletten section minus der höhe der GmAnchorNav
			top: 65px; bottom: 65px;
			@media @mediaQuery__md { top: 60px; bottom: 60px; }
			@media @mediaQuery__sm { top: 55px; bottom: 55px; }

			min-width: 1em;
			overflow: hidden;
			pointer-events: none;
			z-index: 20;
			opacity: 0.75;
			color: black;

			// show the index for debug
			&::before { content: "&__changeAnchorNavBgSentinel " attr(data-index); }
			[showBorders5] & { visibility: visible; }
		}
		&__changeAnchorNavBgDebug {
			display: none;

			position: fixed;
			top: 40vh;
			left: 1rem;
			padding: 0.5rem;
			background-color: black;
			z-index: 1000;

			text-align: center;
			color: white;

			/*
			font-family: monospace;
			font-size: 12px;
			line-height: 1.2em;
			color: black;
			white-space: pre;
			*/

			[showBorders5] & { display: block; }
		}

		&--noMaxWidth &__inner {
			max-width: 100%;
		}
		&--noPaddingTop {
			padding-top: 0;
		}
		&--noPaddingBelow {
			padding-bottom: 0;
		}
		&--noPaddingTopForNextSection + & {
			padding-top: 0;
		}
		&--offscreen {
			transform: translateY(-100%);
		}
		&--includesGmMainHeader {
			z-index: 11 !important; // above default sticky z-index
		}
	}
</style>
