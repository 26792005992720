<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmChapterText
			title="Zugriffsdaten"
			text="<p>Lorem ipsum fox brown jump!</p>"
		></GmChapterText>
	
	2019-06-08	init

-->

<template>
	<!-- <div class="GmChapterText contentModule grid color XXcolor--almostBlack"> -->
	<div class="GmChapterText contentModule">
		<div class="contentModule__inner grid grid--GmChapterText">
			<h3 class="GmChapterText__title font font--uppercase font--mono font--bold font--sizeSmall" v-html="title"></h3>
			<div class="GmChapterText__text font font--serif font--sizeMedium" v-html="text"></div>
		</div>	
	</div>	
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmChapterText',
		components: {},
		mixins: [],
		props: {
			title: [String],
			text: [String],
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	
	// layout: alle deklarations bzgl. position und dimension
	.GmChapterText {		
		// bringe den title auf die gleiche grundlinie wie den text
		&__title {
			padding-top: 0.85em;
			
			@media @mediaQuery__md { 
				padding-top: 0.75em;
			}
			@media @mediaQuery__sm { 
				//
			}
		}
	}
	
	// appearance: alle deklarations außer position und dimension
	.GmChapterText {
		a {
			transition: opacity 0.1s ease;
			color: inherit;
			opacity: 0.5;
		}
		a:hover {
			opacity: 1;
		}
	}
</style>
