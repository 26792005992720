<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<GmImage
			:image="{}"
			imageLink="http://localhost:3000"
			:fullBleed="false"
		></GmImage>

	2019-06-08	init

-->

<template>
	<div class="GmImage contentModule" :class="elmClasses" v-resize:throttle="onElmResize">
		<div class="GmImage__inner contentModule__inner" :class="innerClasses">

			<div class="GmImage__mediaWrapper" :class="mediaWrapperClasses">
				<!-- for debugging fullBleed behavior -->
				<div class="GmImage__mediaWrapperDebug" v-if="fullBleed">
					elmWidth : {{mediaWrapperElm.elmWidth}}<br />
					elmHeight : {{mediaWrapperElm.elmHeight}}<br />
					breakpoint : {{mediaWrapperElm.breakpoint}}<br />
				</div>

				<!-- show the media -->
				<template v-if="fullBleed">
					<template v-if="video">
						<MhVideo
							class="GmImage__mhVideo"
							:videoObject="video"
							:autoplay="true"
						></MhVideo>
					</template><template v-else>
						<template v-if="imageLink">
							<a class="block link link--warpsImage" :href="imageLink">
								<MhImage class="GmImage__mhImage"
									:imageObject="image"
									mode="cover"
									:savePixel="50"
									checkSizeBy="height"
								></MhImage>
							</a>
						</template><template v-else>
							<MhImage class="GmImage__mhImage"
								:imageObject="image"
								mode="cover"
								:savePixel="50"
								checkSizeBy="height"
							></MhImage>
						</template>
					</template>
				</template><template v-else>
					<template v-if="video">
						<MhVideo
							class="GmImage__mhVideo"
							:videoObject="video"
							:autoplay="true"
						></MhVideo>
					</template><template v-else>
						<template v-if="imageLink">
							<a class="block link link--warpsImage" :href="imageLink">
								<MhImage class="GmImage__mhImage"
									:imageObject="image"
									mode="img"
									XXXsavePixel="350"
									XXXcheckSizeBy="height"
								></MhImage>
							</a>
						</template><template v-else>
							<MhImage class="GmImage__mhImage"
								:imageObject="image"
								mode="img"
								XXXsavePixel="350"
								XXXcheckSizeBy="height"
							></MhImage>
						</template>
					</template>
				</template>
			</div>
		</div>
		<!--
		<pre data-name="image">{{image}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import resize from 'vue-resize-directive'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhVideo from '@/components/MhVideo/MhVideo.vue'

	export default {
		name: 'GmImage',
		components: {
			MhImage,
			MhVideo,
		},
		directives: {
			resize,
		},
		mixins: [],
		props: {
			image    : [Object, Boolean],
			imageLink: [String, Boolean],
			video    : [Object, Boolean],
			fullBleed: [Boolean],
		},
		data() {
			return {
				mediaWrapperElm : {
					elmWidth   : null,
					elmHeight  : null,
					breakpoint : null,
				},
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []

				if( this.fullBleed ){
					classes.push('GmImage--fullBleed')
				}

				return classes
			},
			innerClasses(){
				let classes = []

				if( !this.fullBleed ){
					classes.push('grid')
					classes.push('grid--GmImage')
				}

				return classes
			},

			mediaWrapperClasses(){
				let classes = []

				if( this.fullBleed ){
					classes.push('GmImage__mediaWrapper--breakpoint-' + this.mediaWrapperElm.breakpoint )
				}

				return classes
			},
		},
		methods: {
			onElmResize(){ // set mediaWrapperElm vars
				const doLog     = false
				const elmWidth  = this.$el.clientWidth
				const elmHeight = this.$el.clientHeight

				if( elmWidth < 1314 ) this.mediaWrapperElm.breakpoint = 'small'
				if( elmWidth > 1314 ) this.mediaWrapperElm.breakpoint = 'medium'
				if( elmWidth > 1920 ) this.mediaWrapperElm.breakpoint = 'large'

				this.mediaWrapperElm.elmWidth  = elmWidth
				this.mediaWrapperElm.elmHeight = elmHeight

				if( doLog ){
					console.group('onElmResize()')
					console.log('this.$el.clientWidth:', elmWidth)
					console.log('this.$el.clientWidth:', elmHeight)
					console.groupEnd()
				}
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";

	.GmImage {
		position: relative;

		[showBorders3] & { background-color: fade( magenta, 20 ); }
		[showBorders3] & { outline: 1px solid magenta; }
	}

	.GmImage--fullBleed {
		.GmImage__mediaWrapper {
			position: relative;
			overflow: hidden; // needed for the video, otherwise it will overflow the wrapper

			&--breakpoint-small {
				//[showBorders3] & { background-color: fade(green, 25); }
				padding-bottom: 53.27%;
			}
			&--breakpoint-medium {
				//[showBorders3] & { background-color: fade(blue, 25); }
				height: 700px;
			}
			&--breakpoint-large {
				//[showBorders3] & { background-color: fade(red, 25); }
				//padding-bottom: 43.125%;
				//padding-bottom: 53.191%;
				padding-bottom: 36.458%;
			}
		}
		.GmImage__mediaWrapperDebug {
			[showBorders3] & { display: block; }

			position: absolute;
			background-color: fade(magenta, 75);
			top: 0; right: 0;
			font-size: 13px;
			line-height: 1.2em;
			padding: 0.25em;
			color: black;
			z-index: 2;
			display: none;
		}

		.GmImage__mhVideo,
		.GmImage__mhImage {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		.MhVideo__inner {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			padding-bottom: 0 !important;
		}
		.MhVideo__videoElm {
			object-fit: cover;
		}
	}
</style>
