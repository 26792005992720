<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmAnchorNav
			:anchors="[
				{ 'label' : 'Analyse', 'target' : 1 },
				{ 'label' : 'Konzeption', 'target' : 2 },
				{ 'label' : 'Design', 'target' : 3 },
				{ 'label' : 'Realisierung', 'target' : 4 },
			]"
		></GmAnchorNav>
	
	2019-06-08	init

-->

<template>
	<div class="GmAnchorNav contentModule ">
		<div class="GmAnchorNav__inner contentModule__inner grid grid--GmAnchorNav">
			<nav class="GmAnchorNav__wrapper font font--sizeDefault font--mono color color--almostWhite">
				<template 
					v-for="(anchor, index) in _anchors">
					<a 	class="GmAnchorNav__link"
						:class="anchorClasses( anchor )" 
						:key="index"
						@click="clickAnchor( anchor.target, index )">
						{{anchor.label}}
					</a>
					<span class="GmAnchorNav__seperator" :key="'key-'+index">›</span>
				</template>
			</nav>
			<div class="GmAnchorNav__debug">
				<pre data-name="_anchors">{{_anchors}}</pre>
				<pre data-name="valid">{{validSections}}</pre>
				<pre data-name="validVisible">{{validVisibleSections}}</pre>
				<pre data-name="visible">{{visibleSections}}</pre>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'	
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmAnchorNav',
		components: {},
		mixins: [],
		props: {
			anchors: [Array],
		},
		data() {
			return {
				activeAnchorIndex : null,
				visibleSections : [],
				validSections : [],
				validVisibleSections : [],
				//activeAnchorIndex : null,
			}
		},
		watch: {
			visibleSections ( to, from ){
				//console.log('to', to);
			},
		},
		computed: {
			activeSectionIndex() {
				return 1	
			},
			_anchors(){ // extend anchors array with targetIndex and isActive boolean
				const anchors = []
				
				this._.forEach( this.anchors, anchor => {
					const newObj = this._.clone( anchor ) 
					const targetIndex = parseInt( anchor.target ) - 1
					const isActive = this._.includes( this.visibleSections,targetIndex )
					
					this.$set( newObj, 'targetIndex', targetIndex )
					this.$set( newObj, 'isActive', isActive )
					
					anchors.push( newObj )
				})
				
				return anchors;
			},
			_validVisibleSections() { // hier wird sichergestellt, das immmer die erste section active ist, wenn es sonst keine gibt
				const validVisibleSections = this.validVisibleSections
				
				if( !validVisibleSections.length && this.validSections[0] ){
					//console.log('is empty > choose first', this.validSections[0])					
					return [ this.validSections[0] ]
				}
				else{
					return this.validVisibleSections
				}
			},
		},
		methods: {
			anchorClasses( anchor ){
				let classes = []
				
				if( anchor.targetIndex == this._.last( this._validVisibleSections ) ){
					classes.push('GmAnchorNav__link--isActive')
				}
				
				return classes
			},
			clickAnchor( targetIndex, anchorIndex ){
				targetIndex = parseInt( targetIndex ) - 1
				
				const selector = '.GmSection__anchor--'+targetIndex
				let targetElm = document.querySelector( selector )
				const scrollTopOffset = 20
				
				// Der erste AnchorLink scrollt immer ganz an den Anfang der Seite,
				// also zur ersten '.GmSection' im Dokument
				if( anchorIndex == 0 ){
					targetElm = document.querySelector( '.GmSection' )
				}
				
				/*
				console.log('')
				console.group('clickAnchor('+targetIndex+')')
				console.log('targetIndex:', targetIndex)
				console.log('anchorIndex:', anchorIndex)
				console.log('selector:', selector)
				console.log('targetElm:', targetElm)
				console.groupEnd('clickAnchor('+targetIndex+')')				
				*/
				if( targetElm ) {
					
					targetElm.scrollIntoView({
						top: scrollTopOffset + 'px',
						behavior: 'smooth'
					})
					
					this.activeAnchorIndex = anchorIndex
				
				}
			},
			getBodyScrollTop () { // iOS works with document.scrollingElement
				const el = document.scrollingElement || document.documentElement 
				return el.scrollTop 
			},
			scrollHandler( e = null ){ // informs about the scrollDirection 
				//const currentScrollTop = document.documentElement.scrollTop
				const currentScrollTop = this.getBodyScrollTop()
				const scrollDelta      = currentScrollTop - this.prevScrollTop
				const elmBounding      = this.$el.getBoundingClientRect()
				const elmHeight        = elmBounding.height
				const elmTop           = elmBounding.top
				const inView           = ( elmHeight + elmTop ) > 0 ? true : false
				let   scrollDirection  = null					
				const debugLog         = false
				
				// set direction but not on mount where no scroll had happend
				/*
				if( this.prevScrollTop !== null ) {
					scrollDirection  = scrollDelta > 0 ? '↓' : '↑'	
				}
				*/
				
				if( debugLog ){					
					console.group('scrollHandler()')
					console.log('currentScrollTop', currentScrollTop)
					//console.log('scrollDelta', scrollDelta)
					//console.log('scrollDirection', scrollDirection)
					//console.log('inView', inView)
					console.groupEnd()
				}
				
				/*
				this.prevScrollTop = currentScrollTop
				this.currentScrollTop = currentScrollTop
				this.scrollDirection = scrollDirection
				this.inView = inView
				*/
			},
		},
		created() {},
		mounted() {
			// die umgebenede section wird sticky gesetzt
			this.$parent.isSticky = true
			this.$parent.includesGmAnchorNav = true
			
			// what are the validSections?
			this._.forEach( this.anchors, anchor => {
				const targetIndex = parseInt( anchor.target ) - 1
				
				this.validSections.push( targetIndex )
			})
			
			// react to GmSection__visibilityChanged
			EventBus.$on('GmSection: visibilityChanged', sectionData => { // visibleSections stores all visible sections

				const anchors = this.anchors
				const elmIndex = sectionData.elmIndex
				const isVisible = sectionData.isVisible				
				
				/*
				console.log('');
				console.group('GmSection__visibilityChanged');
				console.log('elmIndex:', elmIndex)
				console.log('isVisible:', isVisible, isVisible ? '✅' : '❌')
				console.groupEnd()
				*/
				
				//this.validVisibleSections = []
				
				if( sectionData.isVisible ){
					this.visibleSections.push( elmIndex )
					
					if( this.validSections.includes( elmIndex ) ){
						this.validVisibleSections.push( elmIndex )
					}
				}
				else{
					this.visibleSections = this._.without( this.visibleSections, elmIndex )
					this.validVisibleSections = this._.without( this.validVisibleSections, elmIndex )
				}
			})
		
			// add handler for: change background depending on underlaying section
			window.addEventListener('scroll', this.scrollHandler)
		},
		destroyed() {
			// remove handle for: change background depending on underlaying section
			window.removeEventListener('scroll', this.scrollHandler)
		},
	}
</script>

<style lang="less">
	@import "./../less/vars.less";
	
	// layout: alle deklarations bzgl. position und dimension
	.GmAnchorNav {
		&__wrapper {
			display: flex;
			justify-content: space-between;
		}
		
		&__debug {
			position: fixed;
			bottom: 0; left: 0;
			display: none;
			font-size: 0.75em;
			line-height: 1.1em;
			
			//[showBorders3] & { display: block; }
		}
	}
	
	// appearance: alle deklarations außer position und dimension
	.GmAnchorNav {	
		&__debug {
			background-color: pink;
			padding: 0.5rem;
		}
		
		&__link {}
		&__seperator {
			opacity: 0.5;
			
			&:last-child { 
				display: none; 
			} 
		}		
		
		a {
			transition: all 0.1s ease;
			color: inherit;
			text-decoration: none;
			opacity: 0.5;
			padding-bottom: 0.21rem;
			border-bottom: 3px solid transparent;
		}
		a:hover {
			opacity: 1;
		}
		
		a&__link--isActive { 
			opacity: 1;
			border-color: currentColor;
		}
	}
</style>
