<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmTwoImages
			:firstImage="{}"
			:secondImage="{}"
			firstImageLink=""
			secondImageLink=""
			:firstUp="false"
		></GmTwoImages>
	
	2019-06-08	init

-->

<template>
	<div class="GmTwoImages contentModule" :class="elmClasses">
		<div class="contentModule__inner grid grid--GmTwoImages">
			<div class="GmTwoImages__col GmTwoImages__col--1">
				<div class="GmTwoImages__mediaWrapper">				
					<!-- spacer to h-center the first media -->
					<template v-if="secondVideo">
						<div class="GmTwoImages__spacerElm" v-if="!firstUp">
							<div class="GmTwoImages__spacerInner" 
								 :style="spacerInnerStyles( secondVideo )"
							></div>
						</div>
					</template>
					<template v-else>
						<div class="GmTwoImages__spacerElm" v-if="!firstUp">
							<div class="GmTwoImages__spacerInner" 
								 :style="spacerInnerStyles( secondImage )"
							></div>
						</div>
					</template>
					
					<!-- there is a video -->				
					<template v-if="firstVideo">
						<template v-if="firstImageLink">
							<a class="block link link--warpsImage" :href="firstImageLink">
								<MhVideo :videoObject="firstVideo"></MhVideo>
							</a>				
						</template><template v-else>
							<MhVideo :videoObject="firstVideo"></MhVideo>
						</template>
					</template>
					
					<!-- there is just a image -->
					<template v-else>
						<template v-if="firstImageLink">				
							<a class="block link link--warpsImage" 
							   :href="firstImageLink">
								<MhImage :imageObject="firstImage"></MhImage>
							</a>				
						</template>
						<template v-else>
							<MhImage :imageObject="firstImage"></MhImage>
						</template>
					</template>
				</div>
			</div>
			<div class="GmTwoImages__col GmTwoImages__col--2">
				<div class="GmTwoImages__mediaWrapper">				
					<!-- spacer to h-center the second media -->
					<template v-if="firstVideo">
						<div class="GmTwoImages__spacerElm" v-if="firstUp">
							<div class="GmTwoImages__spacerInner" 
								 :style="spacerInnerStyles( firstVideo )"
							></div>
						</div>
					</template>
					<template v-else>
						<div class="GmTwoImages__spacerElm" v-if="firstUp">
							<div class="GmTwoImages__spacerInner" 
								 :style="spacerInnerStyles( firstImage )"
							></div>
						</div>
					</template>
					
					<!-- there is a video -->				
					<template v-if="secondVideo">
						<template v-if="secondImageLink">
							<a class="block link link--warpsImage" :href="secondImageLink">
								<MhVideo :videoObject="secondVideo"></MhVideo>
							</a>				
						</template><template v-else>
							<MhVideo :videoObject="secondVideo"></MhVideo>
						</template>
					</template>
					
					<!-- there is just a image -->
					<template v-else>
						<template v-if="secondImageLink">				
							<a class="block link link--warpsImage" 
							   :href="secondImageLink">
								<MhImage :imageObject="secondImage"></MhImage>
							</a>				
						</template>
						<template v-else>
							<MhImage :imageObject="secondImage"></MhImage>
						</template>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhVideo from '@/components/MhVideo/MhVideo.vue'

	export default {
		name: 'GmTwoImages',
		components: {
			MhImage,
			MhVideo,
		},
		mixins: [],
		props: {
			firstImage: [Object, Boolean],
			secondImage: [Object, Boolean],
			firstImageLink: [String, Boolean],
			secondImageLink: [String, Boolean],
			firstVideo: [Object, Boolean],
			secondVideo: [Object, Boolean],
			firstUp: [Boolean],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []
				
				/*
				if( this.firstUp ){
					classes.push('GmTwoImages--firstUp')
				}else{
					classes.push('GmTwoImages--secondUp')
				}
				*/
				
				return classes
			},
		},
		methods: {
			spacerInnerStyles( mediaObject ){
				const height        = mediaObject ? mediaObject.height : null
				const width         = mediaObject ? mediaObject.width : null
				const paddingBottom = mediaObject ? ( Math.round( (height / width) * 100 ) / 2 ) + '%' : null; 
				
				return {
					paddingBottom : paddingBottom,
				}
			}
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	
	// layout: alle deklarations bzgl. position und dimension
	.GmTwoImages {}
	
	// appearance: alle deklarations außer position und dimension
	.GmTwoImages {
		&__spacerElm {
			//background-color: fade( red, 20 );
		}
	}
</style>
