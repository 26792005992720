<template>
	<div class="app handleContentModuleVSpacing handleContentModuleHSpacing handleFontSizeExceptions"
		 :style="elmStyles"
		 :class="elmClasses">

		<div class="app__header">
			<!--
			</div><MainNav></MainNav>
			<GmSection class="GmSection--noMaxWidth">
				<GmMainHeader></GmMainHeader>
			</GmSection>
			-->
		</div>
		<div class="app__body">
			<!--
			<MhView
				:spinnerSize="'3em'"
				:spinnerColor="'white'"
			></MhView>
			-->
			<transition
				name="fade"
				mode="out-in"
				:duration="{ leave: 200, enter: 200  }"
				appear>
				<router-view class="app__bodyInner" :key="$route.fullPath" />
			</transition>

			<div class="app__loadingSpinner"></div>

			<GmSlideOutFooter
				v-if="_.get(app, 'acfOptions.slideoutImage', false)"
			></GmSlideOutFooter>
		</div>

		<!--
		<GmSlideOutFooter
			v-if="_.get(app, 'acfOptions.slideoutImage', false) && viewIsReady"
		></GmSlideOutFooter>
		-->

		<!--
		<MhExampleVueperSlides />
		-->

		<GmTint></GmTint>
		<MhDelegateLinks />
		<MhGetScrollbarWidth />
		<MhDevInfos :showOnHosts="['localhost', 'genussmarken.local', 'dev.genussmarken.de']" />
	</div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import resize from 'vue-resize-directive'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MhDevInfos from '@/components/MhDevInfos/MhDevInfos.vue'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import MhDelegateLinks from '@/components/MhDelegateLinks/v5/MhDelegateLinks.vue'
	import MhGetScrollbarWidth from '@/components/MhGetScrollbarWidth/MhGetScrollbarWidth.vue'
	import MhExampleVueperSlides from '@/components/MhExamples/MhExampleVueperSlides.vue'

	import GmTint from '@/components/GmTint.vue'
	import GmSection from '@/components/GmSection.vue'
	import GmMainHeader from '@/components/GmMainHeader.vue'
	import GmSlideOutFooter from '@/components/GmSlideOutFooter.vue'

	export default {
		name: 'app',
		components: {
			MhDevInfos,
			MhDevGrid,
			MhDelegateLinks,
			MhGetScrollbarWidth,
			MhExampleVueperSlides,
			GmTint,
			GmSection,
			GmMainHeader,
			GmSlideOutFooter,
		},
		directives: {
			resize,
		},
		mixins: [ RestHandler ],
		data() {
			return {
				acfOptions : null,
				//bgColor : '#4B4B4B',
				//textColor : '#FCFBF4',
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmStyles(){
				return {
					//backgroundColor : this.bgColor,
					//color : this.textColor,
				}
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {
			setMeasureVars(){
				const root = document.documentElement
				root.style.height = '101vh'
				const scrollbarWidth = window.innerWidth - root.offsetWidth + 'px'
				root.style.height = ''

				root.style.setProperty('--scrollbarWidth', scrollbarWidth)
				//console.log(scrollbarWidth )
			},
		},
		mounted() {
			// fetch acfOptions
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					this.acfOptions = response.data.result
				},
			})

			/*
			// if background color change is emitted
			EventBus.$on('bgColorChanged', bgColor => {
				//console.log('bgColorChanged', bgColor);

				this.bgColor = bgColor
			})
			// if text color change is emitted
			EventBus.$on('textColorChanged', textColor => {
				//console.log('textColorChanged', textColor);

				this.textColor = textColor
			})
			*/
		}
	}
</script>

<style lang="less">
	@import "less/includes/normalize.include.less";
	@import "less/includes/grid.include.less";
	@import "less/vars.less";
	@import "less/fonts.less";
	@import "less/atoms.less";

	body {
		.font;
		.font--sans;
		.font--sizeDefault;

		// neben dem besseren schriftrendering löst das im safari außerdem das flackern des
		// headers wenn ein neues template geladen wird und manchmal wenn text gehovert wird
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: rgb(44, 44, 44);
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: normal;
		margin: 0;
	}
	a {
		-webkit-text-decoration-skip: auto;
		text-decoration-skip-ink: auto;
		text-decoration-skip: auto;
		cursor: pointer;
		color: blue;
	}
	pre {
		@color: black;

		position: relative;
		margin-top: 1em;
		outline: 1px solid fade(@color, 25);
		padding: 0.5em;
		background-color: fade(@color, 5);
		overflow: auto;
		color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;
		//max-height: 200px;


		// label
		&[data-name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(data-name);
			font-family: inherit;
		}

		&:first-child {
			margin-top: 0em;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}
	ol {
		@li__indent: 1.5em;

		.app__bodyInner &:not(.reset--list) {
			counter-reset: section;
			list-style: none;

			li {
				position: relative;
				padding-left: @li__indent;
			}
			li:before {
				position: absolute;
				top: 0; left: 0;

				counter-increment: section;
				content: counters(section,".") ".";
			}
		}
	}
	ul {
		@li__indent: .5em;

		.app__bodyInner &:not(.reset--list) {
			//background-color: fade( red, 20 );
			list-style: none;

			li {
				position: relative;
				padding-left: @li__indent;
			}
			li:before {
				position: absolute;
				top: 0; left: 0;

				content: "•";
			}
		}
	}

	.contentModule {
		[showBorders2] & {
			.outline( yellow );
			.label( attr(class), fade(yellow, 80), black );
		}
	}
	.contentModule__inner {
		[showBorders2] & { outline: 1px solid magenta; }
		//.width;
		//.width--100;
		//.maxWidth;
		//.maxWidth--sectionInner;
		max-width: 1312px;
		margin: 0 auto;
	}
	.contentModule.todo {
		.todo;
	}

	.handleContentModuleHSpacing {

		// der mindest rand der module
		//.MhDevGrid__cols,
		.contentModule {
			margin-left: @contentModule__hMargin;
			margin-right: @contentModule__hMargin;
		}
		.MhDevGrid__cols {
			padding-left: @contentModule__hMargin;
			padding-right: @contentModule__hMargin;
		}
		.GmAnchorNav {
			//margin-left: 5rem;
			//margin-right: 5rem;
		}
		.GmMainHeader__menu {
			padding-left: @contentModule__hMargin;
			padding-right: @contentModule__hMargin;
		}

		// diese module werden full span bei md
		@media @mediaQuery__dt {
			.contentModule.GmImage--fullBleed,
			.contentModule.GmSliderFullBleed {
				margin-left: 0;
				margin-right: 0;

				.contentModule__inner {
					max-width: 100%;
				}
				//margin-left: @contentModule__hMarginMd;
				//margin-right: @contentModule__hMarginMd;
			}
		}
		@media @mediaQuery__md {
			/*
			.contentModule.GmAnchorNav,
			.contentModule.GmImageColumns,
			.contentModule.GmProcessColumns,
			.contentModule.GmSlider,
			.contentModule.GmImage {
				margin-left: 0;
				margin-right: 0;
				margin-left: 40px;
				margin-right: 40px;
			}
			*/

			.contentModule {
				margin-left: @contentModule__hMarginMd;
				margin-right: @contentModule__hMarginMd;
			}
			.contentModule.GmTwoImages,
			.contentModule.GmImage--fullBleed,
			.contentModule.GmSliderFullBleed,
			.contentModule.GmProcessColumns {
				margin-left: 0;
				margin-right: 0;
			}
			.MhDevGrid__cols {
				padding-left: @contentModule__hMarginMd;
				padding-right: @contentModule__hMarginMd;
			}
			.GmMainHeader__menu {
				padding-left: @contentModule__hMarginMd;
				padding-right: @contentModule__hMarginMd;
			}
		}
		// bei sm reduziert sich der linke und rechte rand
		@media @mediaQuery__sm {
			.contentModule {
				margin-left: @contentModule__hMarginSm;
				margin-right: @contentModule__hMarginSm;
			}
			.GmImageColumns,
			.GmSlider {
				margin-left: 0;
				margin-right: 0;
			}
			.contentModule.GmAnchorNav {
				margin-left: @contentModule__hMarginSm;
				margin-right: @contentModule__hMarginSm;
			}
			.MhDevGrid__cols {
				padding-left: @contentModule__hMarginSm;
				padding-right: @contentModule__hMarginSm;
			}
		}

	}
	.handleContentModuleVSpacing {

		.contentModule.GmMainFooter,
		.contentModule.GmProjectFooterNav,
		.contentModule.GmText .font--mono {
			padding-top: 2rem;
			padding-bottom: 2rem;

			@media @mediaQuery__md {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
			@media @mediaQuery__sm {
				padding-top: 0rem;
				padding-bottom: 0rem;
			}
		}
		.contentModule.GmText .font--sizeMediumSans2 {
			padding-top: 0.2rem;

			@media @mediaQuery__md {
				padding-top: 0.3rem;
			}
		}


	}
	.handleFontSizeExceptions {	// behandelt alle schriftgrößen sonderfälle
		.GmTestimonial {
			&__quote.font--sizeMedium {
				@media @mediaQuery__md {
					font-size: 20px;
					line-height: 1.45em;

					font-size: 17px;
					line-height: 1.45em;
				}
				@media @mediaQuery__sm {
					font-size: 17px;
					line-height: 1.45em;
				}
			}
		}
		.GmSpacer {
			&__row.font--sizeDefault {
				font-size: 17px;
				line-height: 17px;

				@media @mediaQuery__md {
					font-size: 17px;
					line-height: 17px;
				}
				@media @mediaQuery__sm {
					font-size: 10px;
					line-height: 10px;
				}
			}
		}
		.GmChapterText {
			&__text.font--sizeMedium {
				@media @mediaQuery__md {
					font-size: 20px;
					line-height: 1.45em;
				}
				@media @mediaQuery__sm {
					font-size: 17px;
					line-height: 1.45em;
				}
			}
		}
		.GmAnchorNav {
			&__wrapper.font--sizeDefault {
				@media @mediaQuery__sm {
					font-size: 10px;
					line-height: 17px;
				}
			}
		}
		.GmHeadline {
			&__title.font--sizeDefault {
				@media @mediaQuery__sm {
					font-size: 10px;
					line-height: 17px;
				}
			}
		}
		.GmProcessColumns {
			.diagram.font--sizeSmall {
				@media @mediaQuery__sm {
					font-size: 9px;
					line-height: 12px;
				}
			}
		}
		.GmCompanyLocations {
			&__addressText {
				font-size: 1.29em;
				line-height: 1.4em;
			}
			@media @mediaQuery__dt { // dt übernimmt die font-sizes von md
				&__headerText {
					font-size: 12px;
					line-height: 15px;
				}
				&__addressText {
					font-size: 15.48px;
				}
				&__footerText {
					font-size: 12px;
					line-height: 15px;
				}
			}
		}
		.GmTestimonial {
			@media @mediaQuery__md {
				&__services {
					font-size: 19px;
					line-height: 25px;
				}
			}
		}
	}

	.app {
		//background-color: red !important; // TODO: remove this, just for debugging

		//min-height: 101vh; // make sure that there are always scrollbars
	}
	.app__nav {}
	.app__header {}
	.app__body {}
	.app__bodyInner {}
	.app__loadingSpinner {
		@outer__width  : 3em;
		@inner__width  : 2em;
		@stroke__width : 3px;
		@duration      : 1s;
		@keyframes spin {
			100% { transform:rotate(360deg); }
		}

		position: fixed;
		top: calc( 50vh );
		left: calc( 50% );
		height: @outer__width;
		width: @outer__width;
		transform: translateX( -50% ) translateY( -50% );
		z-index: 200;
		//background-color: currentColor;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		//opacity: 0;
		pointer-events: none;
		color: red;

		&::before{
			display: block;
			height: @inner__width; width: @inner__width;
			border-radius: inherit;
			animation: spin @duration linear infinite;
			content: "";
		}
		&::before{
			border-top:    @stroke__width solid lighten( black, 80 );
			border-right:  @stroke__width solid lighten( black, 50 );
			border-bottom: @stroke__width solid lighten( black, 50 );
			border-left:   @stroke__width solid lighten( black, 50 );
		}



		@media @mediaQuery__xs {}
		@media @mediaQuery__sm {}
		@media @mediaQuery__md {
			//top: calc( 1rem );
			//left: calc( 0.75rem );
		}
		@media @mediaQuery__dt {}
		@media @mediaQuery__lg {}
		@media @mediaQuery__xl {}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
