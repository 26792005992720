/*
	
	Some useful helper functions
	
	2019-07-01	init

*/

export default {
	name: 'MhHelper',
	computed: {},
	methods: {
		urlPathname( url ){
			const _url = new URL( url )
			return _url ? _url.pathname : null
		}
	},
	beforeCreate() {},
	created() {},
	mounted() {},
}
