<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmTeaserImagesInColums
			firstColImages="[1,2,3,4]"
			secondColImages="[1,2,3]"
			thirdColImages="[1,2,3]"
			fourthColImages="[1,2,3,4]"
		></GmTeaserImagesInColums>
	
	2019-06-08	init

-->

<template>
	<div class="GmTeaserImagesInColums contentModule">
		<div class="contentModule__inner grid grid--GmTeaserImagesInColums">
			<div class="GmTeaserImagesInColums__col GmTeaserImagesInColums__col--1">
				<div class="GmTeaserImagesInColums__imageWrapper dummyImage" v-for="(image, index) in firstColImages" :key="index">{{image}}</div>
			</div>
			<div class="GmTeaserImagesInColums__col GmTeaserImagesInColums__col--2">
				<div class="GmTeaserImagesInColums__imageWrapper dummyImage" v-for="(image, index) in secondColImages" :key="index">{{image}}</div>
			</div>
			<div class="GmTeaserImagesInColums__col GmTeaserImagesInColums__col--3">
				<div class="GmTeaserImagesInColums__imageWrapper dummyImage" v-for="(image, index) in thirdColImages" :key="index">{{image}}</div>
			</div>
			<div class="GmTeaserImagesInColums__col GmTeaserImagesInColums__col--4">
				<div class="GmTeaserImagesInColums__imageWrapper dummyImage" v-for="(image, index) in fourthColImages" :key="index">{{image}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmTeaserImagesInColums',
		components: {},
		mixins: [],
		props: {
			firstColImages: [Array],
			secondColImages: [Array],
			thirdColImages: [Array],
			fourthColImages: [Array],
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@colGap : 1rem;
	@up     : 3rem;
	
	.GmTeaserImagesInColums {
		background-color: red;
		
		&__col {}
		&__col--1 { padding-top: @up; }
		&__col--3 { padding-top: @up; }
		&__imageWrapper {
			margin-bottom: @colGap;
		}
	}
</style>
