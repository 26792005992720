<template>
	<div class="MhExampleVueperSlides" :class="elmClasses" :style="elmStyles">		
		<h1>VueperSlides Example</h1>
		<hr/>
		
		<a href="https://antoniandre.github.io/vueper-slides/">https://antoniandre.github.io/vueper-slides/</a>
		
		<hr/>
		<h2>#1 • Simple Example</h2>

		<VueperSlides class="no-shadow">
			<VueperSlide v-for="(slide, i) in slides" :key="i" :title="slide.title" :content="slide.content" />
		</VueperSlides>		
		
		<hr/>
		<h2>#2 • With :dragging-distance="70" and prevent-y-scroll</h2>
		
		<VueperSlides class="no-shadow"
			:dragging-distance="70" 
			prevent-y-scroll
		>
			<vueper-slide
				v-for="i in 4"
				:key="i"
				:style="'background-color: ' + ['#ff5252', '#42b983'][i % 2]">
				<template v-slot:content>
					<i class="icon icon-check"></i>
					Complex content with Vue.js {{ 1 === 1 ? 'interpreted' : 'non-interpreted' }} compilable content & <span v-pre>{{ mustaches }}</span>.
				</template>
			</vueper-slide>
		</VueperSlides>		
		<hr/>

		<h2>#3 • With fade transitionSpeed autoplay :touchable="false"</h2>
		
		<VueperSlides class="no-shadow"
			fade 
			transitionSpeed="750" 
			duration="5000" 
			autoplay
			:touchable="false"
			:pauseOnHover="false"
			:bullets="false"
			@ready="onSliderReady"
			@slide="onSlide"
		>
			<template v-slot:arrow-left>
				prev 
			</template>			
			<template v-slot:arrow-right>
				next 
			</template>

			<vueper-slide
				v-for="i in 4"
				:key="i"
				:style="'background-color: ' + ['#ff5252', '#42b983'][i % 2]">
				<template v-slot:content>
					<div class="vueperslide__content">
						Complex content with Vue.js {{ 1 === 1 ? 'interpreted' : 'non-interpreted' }} compilable content & <span v-pre>{{ mustaches }}</span>.
						<div class="progressBar">
							<div class="progressBar__progress" :style="{ width : progressBar.width + '%', transitionDuration : progressBar.transitionDuration + 'ms' }"></div>
						</div>
					</div>
				</template>
			</vueper-slide>
		</VueperSlides>
	</div>
</template>

<script>
	//import Slider from 'vue-plain-slider'	
	import { VueperSlides, VueperSlide } from 'vueperslides'
	import 'vueperslides/dist/vueperslides.css'

	export default {
		name: 'MhExampleVueperSlides',
		components: { VueperSlides, VueperSlide },
		props: {},
		data(){
			return {
				debug: false,
				slides : [
					{ title : 'title1', content : 'content1' },
					{ title : 'title2', content : 'content2' },
					{ title : 'title3', content : 'content3' },
				],
				progressBar : {
					transitionDuration : 5000,
					width : 0
				},
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmStyles(){
				return {
					//backgroundColor : this.bgColor,
					//color : this.textColor,
				}
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {
			onSliderReady( e, doLog = true ){
				if( doLog ){
					console.group( this.$options.name, '• onReady()')
					console.log('e:', e)
					console.groupEnd()
				}
				this.progressBar.width = 0
				this.progressBar.transitionDuration = 0

				setTimeout(()=>{
					this.progressBar.transitionDuration = 5000-500
				}, 50)
				setTimeout(()=>{
					this.progressBar.width = 100
				}, 100)
			},
			onSlide( e, doLog = true ){
				// group groupCollapsed
				if( doLog ){
					console.group( this.$options.name, '• onSlide()')
					console.log('e:', e)
					console.groupEnd()
				}
				this.progressBar.width = 0
				this.progressBar.transitionDuration = 0

				setTimeout(()=>{
					this.progressBar.transitionDuration = 5000-100
				}, 50)
				setTimeout(()=>{
					this.progressBar.width = 100
				}, 100)
			},
		},
		mounted( doLog = true ){
			// group groupCollapsed
			if( doLog ){
				console.groupCollapsed( this.$options.name, '• mounted()')
				console.log('VueperSlides:', VueperSlides)
				console.log('VueperSlide:', VueperSlide)
				console.groupEnd()
			}
		},
	}
</script>

<style lang="less">
	.MhExampleVueperSlides {
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 100;
		background-color: darken( yellow, 20 );
		padding: 1em;
		overflow: auto;

		h1 {
			font-size: 2.25em;
			line-height: 1.2em;
			font-weight: bold;
			margin: 0em 0 0.5em 0;
		}		
		h2 {
			font-size: 1.5em;
			line-height: 1.2em;
			font-weight: bold;
			margin: 1em 0 0.5em 0;
		}

		.vueperslides {
			outline: 1px solid red;
			background-color: fade( black, 10 );
		}
		.vueperslide {
			padding: 1em;
			display: flex;
		}
		.vueperslide__content {
			background-color: fade( blue, 35 );
			flex-grow: 1;
		}
		.progressBar {
			background-color: fade( white, 50 );
			height: 3px;
			display: flex;

			&__progress {				
				background-color: white;
				width: 25%;
				transition-timing-function: linear;
			}
		}
	}
</style>
