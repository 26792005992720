<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmImageColumns
			desktopFirstColumn="[]"
			desktopSecondColumn="[]"
			desktopThirdColumn="[]"
			desktopFourthColumn="[]"
			mobileFirstColumn="[]"
			mobileSecondColumn="[]"
		></GmImageColumns>
	
	2019-06-08	init

-->

<template>
	<div class="GmImageColumns contentModule" :class="elmClasses">
		
		<!-- mobile: 2 columns -->
		<template v-if="$mq === 'xs' || $mq === 'sm'">
			<div class="contentModule__inner grid grid--GmImageColumns">
				<div class="GmImageColumns__col GmImageColumns__col--1">
					<div class="GmImageColumns__imageWrapper" 
						 v-for="(item, index) in mobileFirstColumn" 
						 :key="'m1' + index">
						<template v-if="item.link">
							<a class="block link link--warpsImage" :href="item.link">
								<MhImage 
									class="GmImageColumns__image"
									:imageObject="item.image">
								</MhImage>				
							</a>
						</template>
						<template v-else>
							<MhImage 
								class="GmImageColumns__image"
								:imageObject="item.image">
							</MhImage>					
						</template>
					</div>
				</div>
				<div class="GmImageColumns__col GmImageColumns__col--2">
					<div class="GmImageColumns__imageWrapper" 
						 v-for="(item, index) in mobileSecondColumn" 
						 :key="'m2' + index">
						<template v-if="item.link">
							<a class="block link link--warpsImage" :href="item.link">
								<MhImage 
									class="GmImageColumns__image"
									:imageObject="item.image">
								</MhImage>				
							</a>
						</template>
						<template v-else>
							<MhImage 
								class="GmImageColumns__image"
								:imageObject="item.image">
							</MhImage>					
						</template>
					</div>
				</div>
			</div>
		</template>
		
		<!-- above mobile: 4 columns -->
		<template v-else>
			<div class="contentModule__inner grid grid--GmImageColumns">
				<div class="GmImageColumns__col GmImageColumns__col--1">
					<div class="GmImageColumns__imageWrapper" 
						 v-for="(item, index) in desktopFirstColumn" 
						 :key="index">
						<template v-if="item.link">
							<a class="block link link--warpsImage" :href="item.link">
								<MhImage 
									class="GmImageColumns__image"
									:imageObject="item.image">
								</MhImage>				
							</a>
						</template>
						<template v-else>
							<MhImage 
								class="GmImageColumns__image"
								:imageObject="item.image">
							</MhImage>					
						</template>
					</div>
				</div>
				<div class="GmImageColumns__col GmImageColumns__col--2">
					<div class="GmImageColumns__imageWrapper" 
						 v-for="(item, index) in desktopSecondColumn" 
						 :key="index">
						<template v-if="item.link">
							<a class="block link link--warpsImage" :href="item.link">
								<MhImage 
									class="GmImageColumns__image"
									:imageObject="item.image">
								</MhImage>				
							</a>
						</template>
						<template v-else>
							<MhImage 
								class="GmImageColumns__image"
								:imageObject="item.image">
							</MhImage>					
						</template>
					</div>
				</div>
				<div class="GmImageColumns__col GmImageColumns__col--3">
					<div class="GmImageColumns__imageWrapper" 
						 v-for="(item, index) in desktopThirdColumn" 
						 :key="index">
						<template v-if="item.link">
							<a class="block link link--warpsImage" :href="item.link">
								<MhImage 
									class="GmImageColumns__image"
									:imageObject="item.image">
								</MhImage>				
							</a>
						</template>
						<template v-else>
							<MhImage 
								class="GmImageColumns__image"
								:imageObject="item.image">
							</MhImage>					
						</template>
					</div>
				</div>
				<div class="GmImageColumns__col GmImageColumns__col--4">
					<div class="GmImageColumns__imageWrapper" 
						 v-for="(item, index) in desktopFourthColumn" 
						 :key="index">
						<template v-if="item.link">
							<a class="block link link--warpsImage" :href="item.link">
								<MhImage 
									class="GmImageColumns__image"
									:imageObject="item.image">
								</MhImage>				
							</a>
						</template>
						<template v-else>
							<MhImage 
								class="GmImageColumns__image"
								:imageObject="item.image">
							</MhImage>					
						</template>
					</div>
				</div>
			</div>	
		</template>
						
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'GmImageColumns',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			desktopFirstColumn : [Array, Boolean],
			desktopSecondColumn : [Array, Boolean],
			desktopThirdColumn : [Array, Boolean],
			desktopFourthColumn : [Array, Boolean],
			mobileFirstColumn : [Array, Boolean],
			mobileSecondColumn : [Array, Boolean],
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";
	
	@up     : 3rem;
	
	.GmImageColumns {
		&__col {}
		&__col--1, &__col--3 {
			padding-top: @up;
		}
		&__imageWrapper:not(:last-child) {
			margin-bottom: @colGap;
		}
		
	}
</style>
