<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<GmProjectsTeasers></GmProjectsTeasers>

	2019-06-25	init

-->

<template>
	<div class="GmProjectsTeasers contentModule" :class="elmClasses">
		<div class="contentModule__inner grid grid--GmProjectsTeasers">
			<div class="GmProjectsTeasers__project" :class="'GmProjectsTeasers__project--colSpan-' + post.acf.teaserColSpan" v-for="(post, index) in posts" :key="index">
				<!-- <router-link :to="{ name: 'ProjectView', params: { slug : post.slug } }"> -->
				<router-link :to="'/arbeit/' + post.slug + '/'">
					<MhImage
						:imageObject="post.acf.teaserImage">
					</MhImage>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'GmProjectsTeasers',
		components: {
			MhImage,
		},
		mixins: [ RestHandler ],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				posts : [],
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {
			// fetch pages
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/posts',
				params : {
					postType : 'projekte_post'
				},
				callback : (response) => {
					this.posts = response.data.result
				},
			})
		},
	}
</script>

<style lang="less" scoped>
	@import "../less/vars.less";

	// layout: alle deklarations bzgl. position und dimension
	.GmProjectsTeasers {}

	// appearance: alle deklarations außer position und dimension
	.GmProjectsTeasers {
		a {
			transition: opacity 0.1s ease;
			display: block;
		}
		a:hover {
			opacity: 0.75;
		}
	}
</style>
