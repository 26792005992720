<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<GmCompanyLocations
			firstTitle="Bremen"
			:firstImage="{}"
			firstText="<p>Alex Böll<br />Künstlerhaus Güterabfertigung <br />Beim Handelsmuseum 9 <br />28195 Bremen<br /></p>"
			firstFooterText="<a>Landkarte</a><a>E-Mail</a>"

			secondTitle="Leipzig"
			:secondImage="{}"
			secondText="<p>Carlo Grabowski <br />Dittrichring 17 <br />04109 Leipzig <br />+49 341 60439287<br /></p>"
			secondFooterText="<a>Landkarte</a><a>E-Mail</a>"
		></GmCompanyLocations>

	2019-06-08	init

-->

<template>
	<div class="GmCompanyLocations contentModule" :class="elmClasses">
		<div class="contentModule__inner grid grid--GmCompanyLocations color color--almostBlack">
			<div class="GmCompanyLocations__col GmCompanyLocations__col--1 bgColor bgColor--almostWhite">
				<!-- there is a video -->
				<template v-if="firstVideo">
					<MhVideo
						class="GmCompanyLocations__video"
						:videoObject="firstVideo"
						:autoplay="true"
					></MhVideo>
				</template>

				<!-- there is just a image -->
				<template v-else>
					<MhImage
						class="GmCompanyLocations__image"
						mode="cover"
						:imageObject="firstImage"
					></MhImage>
				</template>

				<!-- text -->
				<div class="GmCompanyLocations__text text text--center font font--mono font--sizeSmall" :style="textWrapperStyles">
					<div class="GmCompanyLocations__textInner">
						<div class="GmCompanyLocations__headerText font--sizeSmall font--uppercase">
							<strong>{{firstTitle}}</strong><br />
							<br />
						</div>
						<div class="GmCompanyLocations__addressText" v-html="firstText"></div>
						<br />
						<div class="GmCompanyLocations__footerText font--sizeSmall font--bold font--uppercase" v-html="firstFooterText"></div>
					</div>
				</div>
			</div>
			<div class="GmCompanyLocations__col GmCompanyLocations__col--2 bgColor bgColor--almostWhite">
				<!-- there is a video -->
				<template v-if="secondVideo">
					<MhVideo
						class="GmCompanyLocations__video"
						:videoObject="secondVideo"
						:autoplay="true"
					></MhVideo>
				</template>

				<!-- there is just a image -->
				<template v-else>
					<MhImage
						class="GmCompanyLocations__image"
						mode="cover"
						:imageObject="secondImage"
					></MhImage>
				</template>

				<!-- text -->
				<div class="GmCompanyLocations__text text text--center font font--mono font--sizeSmall" :style="textWrapperStyles">
					<div class="GmCompanyLocations__textInner">
						<div class="GmCompanyLocations__headerText font--sizeSmall font--uppercase">
							<strong>{{secondTitle}}</strong>
							<br />
							<br />
						</div>
						<div class="GmCompanyLocations__addressText" v-html="secondText"></div>
						<br />
						<div class="GmCompanyLocations__footerText font--sizeSmall font--bold font--uppercase" v-html="secondFooterText"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhVideo from '@/components/MhVideo/MhVideo.vue'

	export default {
		name: 'GmCompanyLocations',
		components: {
			MhImage,
			MhVideo,
		},
		mixins: [],
		props: {
			firstTitle      : [String],
			firstImage      : [Object, Boolean],
			firstVideo      : [Object, Boolean],
			firstText       : [String],
			firstFooterText : [String],

			secondTitle     : [String],
			secondImage     : [Object, Boolean],
			secondVideo     : [Object, Boolean],
			secondText      : [String],
			secondFooterText: [String],
		},
		data() {
			return {}
		},
		watch: {
			someVar: {}
		},
		computed: {
			elmClasses(){
				let classes = []

				return classes
			},
			textWrapperStyles(){ // set the ratio for the textWrapper depending on image- or video-ratio
				if( this.firstVideo ){
					return {
						paddingBottom : ( this.firstVideo.height / this.firstVideo.width ) * 100 + '%'
					}
				}else{
					return {
						paddingBottom : '64%'
					}
				}
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";

	@imageRatioPadding : 64%;
	//@textRatioPadding : 56.25%;

	// layout: alle deklarations bzgl. position und dimension
	.GmCompanyLocations {
		&__col {
			position: relative;
			border-radius: 5px;
			overflow: hidden; // damit der border radius auch das bild beschneidet

			// safari hack: its neede to clip the video with border-radius
			// taken from https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
			//-webkit-mask-image: -webkit-radial-gradient(white, black);
		}
		&__video,
		&__image {
			background-color: fade(black, 20);
		}
		&__image {
			padding-bottom: @imageRatioPadding;
		}
		&__text {
			//background-color: fade( green, 20 );
			position: relative;
			//padding-bottom: @textRatioPadding;
		}
		&__textInner { // center the text
			position: absolute;
			top: 50%; left: 0; width: 100%;
			transform: translateY(-50%);
			padding: 0 2em
		}
	}

	// appearance: alle deklarations außer position und dimension
	.GmCompanyLocations {
		a {
			color: inherit;
			text-decoration: underline;
		}
	}
</style>
