<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmProcessColumns
			title="Was wir machen"
			linkUrl="//www.taz.de"
			linkText="Mehr Referenzen sehen"
			
			text="Lorem ipsum"
		></GmProcessColumns>
	
	2019-06-08	init

-->

<template>
	<div class="GmProcessColumns contentModule" :class="elmClasses" v-observe-visibility="elmVisibilityChanged">
		<div class="GmProcessColumns__inner contentModule__inner">

			<GmHeadline
				:title="title"
				:linkUrl="linkUrl"
				:linkText="linkText"
			></GmHeadline>
			
			<GmText
				font="serif"
				:text="text"
			></GmText>
			
			<div class="diagram font font--sizeSmall font--mono font--bold font--uppercase color color--almostWhite">
				<div class="diagram__col diagram__col--1" :style="diagramCol1Styling"><span class="diagram__colLabel">Analyse</span></div>
				<div class="diagram__col diagram__col--2" :style="diagramCol2Styling"><span class="diagram__colLabel">Konzeption</span></div>
				<div class="diagram__col diagram__col--3" :style="diagramCol3Styling"><span class="diagram__colLabel">Gestaltung</span></div>
				<div class="diagram__col diagram__col--4" :style="diagramCol4Styling"><span class="diagram__colLabel">Realisierung</span></div>
			</div>
			
			<div class="GmProcessColumns__debug">
				<strong>GmProcessColumns</strong><br />
				<div>      elmIsVisible: {{elmIsVisible}}</div>
				<div>     elmScrolledPx: {{elmScrolledPx}}px</div>
				<div>elmScrolledPercent: {{elmScrolledPercent}}%</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import GmHeadline from '@/components/GmHeadline.vue'
	import GmText from '@/components/GmText.vue'

	export default {
		name: 'GmProcessColumns',
		components: {
			GmHeadline,
			GmText,
		},
		mixins: [],
		props: {
			title: [String],
			linkText: [String],
			linkUrl: [String],
			text : [String],
		},
		data() {
			return {
				visibleParts       : [],
				elmIsVisible       : false,
				elmScrolledPx      : 0,
				elmScrolledPercent : 0,
			}
		},
		watch: {},
		computed: {
			elmClasses() {
				let classes = []
				
				const visiblePartsString = this._.clone(this.visibleParts).sort().join('-')
				
				if(visiblePartsString){
					classes.push('GmProcessColumns--visibleParts-' + visiblePartsString)					
				}
				classes.push('GmProcessColumns--aniVersion-' + this.aniVersion)
				
				return classes
			},
			diagramCol1Styling() {
				let styles = {}
				let offset = (50 - this.elmScrolledPercent) * 1.5
					
				styles.transform = 'translateY( '+offset+'% )'
				
				return styles
			},
			diagramCol2Styling() {
				let styles = {}
				let offset = (50 - this.elmScrolledPercent) * 1	
				
				styles.transform = 'translateY( '+offset+'% )'
				
				return styles
			},
			diagramCol3Styling() {
				let styles = {}
				let offset = (50 - this.elmScrolledPercent) * 1.75	
				
				styles.transform = 'translateY( '+offset+'% )'
				
				return styles
			},
			diagramCol4Styling() {
				let styles = {}
				let offset = (50 - this.elmScrolledPercent) * 1.25	
				
				styles.transform = 'translateY( '+offset+'% )'
				
				return styles
			},
		},
		methods: {
			elmVisibilityChanged( isVisible, entry ){
				this.elmIsVisible = isVisible
			},
			getDomNodeIndex( domNode ){
				const index = [...domNode.parentElement.children].indexOf(domNode)
				
				return index
			},
			handleWindowScrolling( e ){
				const debugLog = false
				
				if( this.elmIsVisible ){
					const roundPrecision = 0 // how many digits after comma
					const elmRect = this.$el.getBoundingClientRect()
					const winInnerHeight = window.innerHeight
					const elmScrolledPx = this._.round( (winInnerHeight - elmRect.top) > 0 ? winInnerHeight - elmRect.top : 0, roundPrecision )
					
					let elmScrolledPercent = this._.round( elmScrolledPx / ((winInnerHeight + elmRect.height) / 100), roundPrecision )
					if( elmScrolledPercent > 100 ) elmScrolledPercent = 100

					if( debugLog ){
						console.group('handleScrolling()')
						//console.log('e:', e )
						console.log('elmRect:', elmRect )
						console.log('winInnerHeight:', winInnerHeight )
						console.log('elmScrolledPx:', elmScrolledPx )
						console.log('elmScrolledPercent:', elmScrolledPercent )
						console.groupEnd()
					}
					
					this.elmScrolledPx = elmScrolledPx
					this.elmScrolledPercent = elmScrolledPercent
				}
			},
		},
		created() {
			window.addEventListener('scroll', this.handleWindowScrolling)
		},
		mounted() {
			this.$parent.includesGmProcessColumns = true			
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleWindowScrolling)
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	
	@elm : {
		padding-top: 20.5rem;
		padding-bottom: 20rem;
	}
	@diagram : {
		top: 0rem; 
		left: 0; 
		right: 0; 
		bottom: 0;	
		colPaddingTop: 2rem;
	}

	[showBorders3] .GmProcessColumns {
		.diagram__col {
			outline: 1px solid red;
		}
		&__debug {
			visibility: visible !important;
		}
		&__sentinels {
			visibility: visible !important;
		}
	}
	
	@height     : 750px;	
	@height__md : 750px;
	@height__sm : 530px;
	
	.GmProcessColumns {
		position: relative;
		padding-top: @elm[padding-top];
		padding-bottom: @elm[padding-bottom];
		
		height: @height;
		@media @mediaQuery__md { height: @height__md; }
		@media @mediaQuery__sm { height: @height__sm; padding-top: 12rem; }
		
		.GmHeadline, .GmText { // to bring them above the diagram
			position: relative; 
			z-index: 3; 
		}
		.GmHeadline { // abstand von "Wie wir arbeiten" zum Fliesstext
			margin-bottom: 1em;
		}
		&__inner {
			position: relative;
		}
		
		&__debug {
			position: fixed;
			top: 50%; left: 0;
			background-color: fade( black, 90 );
			visibility: hidden;
			pointer-events: none;
			z-index: 1000;
			font-size: 14px;
			line-height: 1.35em;
			padding: 0.5em;
			color: fade( white, 90 );
			
			div {
				white-space: pre;
				font-family: monospace;
			}
		}

		@media @mediaQuery__md {}
		@media @mediaQuery__sm {}
		
		.diagram {
			//background-color: fade(yellow, 20);
			
			position: absolute;
			top: @diagram[top]; 
			left: @diagram[left]; 
			right: @diagram[right]; 
			bottom: @diagram[bottom];
			display: flex;
			align-items: flex-end;
			align-items: center;
			
			// offset der cols damit die besser im container sitzen
			transform: translateY(5vh);
			@media @mediaQuery__sm { transform: translateY(-5vh); }
			
			&__col {
				transition: all 0.1s ease;
				
				position: relative;
				padding-top: 2rem;
				min-height: 6rem; // just for dev
				flex-grow: 1;
				text-align: center;
				width: 25%;
				
				// jeder der balken hat außerdem noch 
				// einen verlauf von unten (grün zu transparent)
				&:after {
					//outline: 1px solid red;
					
					position: absolute;
					bottom: 0; left: 0; right: 0;
					height: 70%;
					background: linear-gradient(0deg, #4B4B4B, fade(#4B4B4B, 0));
					content: "";
				}
			}
			// heights
			&__col { 
				height: @height * 0.75;
				@media @mediaQuery__md { height: @height__md * 0.75; }
				@media @mediaQuery__sm { height: @height__sm * 0.75; }
			}
			
			// backgrounds
			&__col--1 { background: linear-gradient(to right, #615A69, fade(#615A69, 0)); }
			&__col--2 { background: linear-gradient(to right, #585555, fade(#615A69, 0)); }
			&__col--3 { background: linear-gradient(to right, #4A5C53, fade(#615A69, 0)); }
			&__col--4 { background: linear-gradient(to right, #5C4A4A, fade(#615A69, 0)); }
			
			// text paddings 
			@media @mediaQuery__md {
				&__col { padding-top: 1rem; }
			}
			@media @mediaQuery__sm {
				&__col { padding-top: 0.5rem; }
				&__colLabel { opacity: 0.5; }
			}
		}
	}
</style>
