<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmSlider
			:images="[]"
			:ratio="0.63"
		></GmSlider>
	
	2019-06-08	init

-->

<template>
	<div class="GmSlider contentModule" :class="elmClasses">
		<VueperSlides 
			v-if="images"
			class="vueperSlides contentModule__inner"
			:infinite="false"
			:disableArrowsOnEdges="true"
			:transitionSpeed="250" 
			:touchable="true"
			:slideRatio="ratioFloat"
			:draggingDistance="70"
			:bullets-outside="false"
			@ready="onSliderReady"
			@slide="onAfterSlide"
			>
			<VueperSlide v-for="(slide, i) in images" :key="i">
				<!-- 
				<div slot="slideContent">{{image.id}}</div>
				-->
				<MhImage class="GmSlider__image"
						 :imageObject="slide.image"
						 mode="cover"
						 slot="slideContent">
				</MhImage>
			</VueperSlide>
		</VueperSlides>
		
		<!-- die captions werden in diesen wrapper ausgelagert -->
		<div class="GmSlider__caption font font--sans text text--center color color--almostWhite" v-html="currentCaption"></div>
		
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'GmSlider',
		components: {
			VueperSlides, 
			VueperSlide,
			MhImage, 
		},
		mixins: [],
		props: {
			images: [Array, Boolean],
			ratio: {
				type: [Number, String],
				default: 0.63,
			},
		},
		data() {
			return {
				currentSlideIndex : 0, // not used
				currentCaption : '',
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []
				
				return classes
			},
			ratioFloat(){
				return parseFloat( this.ratio )
			},
		},
		methods: {
			onSliderReady( e, payload ){
				const currentSlideIndex = payload.currentSlide.index
				const currentCaption = this.images[currentSlideIndex].bu
				
				this.currentSlideIndex = currentSlideIndex
				this.currentCaption = currentCaption
			},
			onAfterSlide( e, payload ){
				const currentSlideIndex = payload.currentSlide.index
				const currentCaption = this.images[currentSlideIndex].bu
				
				this.currentSlideIndex = currentSlideIndex
				this.currentCaption = currentCaption
			}
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">

	@import "./../less/vars.less";
	
	@bullet__height  : 1rem;
	@bullet__padding : 0.25rem;
	@bullet__opacity : 0.35;
	@arrow__shadow   : 0px 0px 5px fade(black, 30);
	@bullet__shadow  : 0px 0px 10px fade(black, 30);
	
	.GmSlider {
		
		.vueperSlides {
			position: relative;
			
			&__arrow {
				//background-color: fade(red, 50);
				transition: opacity 0.3s ease;
				
				position: absolute;
				top: 0; bottom: 0; width: 4rem;
				opacity: 0;
				filter: drop-shadow( @arrow__shadow );
				
				svg {
					//background-color: fade(red, 75);
					
					position: absolute;
					top: 50%; left: 0;					
					transform: translateY(-50%) scale(0.5);
					color: @swatches[almostWhite];
					opacity: 0.65;
				}
			}
			&__arrow--prev {
				left: 0;
			}
			&__arrow--next {
				right: 0;
			}
			&:hover .vueperSlides__arrow { 
				opacity: 1;
			}
			
			&__bullets {
				//background-color: fade( orange, 30 );				
				
				position: absolute;
				left: 0; right: 0;
				bottom: 1.3rem;
				
				@media @mediaQuery__md {
					bottom: 1rem;	
				}
				@media @mediaQuery__sm {
					bottom: 0.5rem;					
				}
			}
			&__bullet {
				//outline: 1px solid red;
				transition: opacity 0.2s ease;				
				display: flex;	
				align-items: stretch;
				
				padding: @bullet__padding; 
				height: @bullet__height;
				width: @bullet__height;

				span { 
					border-radius: 50%;
					font-size: 0; 
					line-height: 0;
					flex-grow: 1;
					background-color: fade( @swatches[almostWhite], 35 );
					box-shadow: @bullet__shadow;
				}
			}
			&__bullet--active {
				opacity: 1;
				padding: @bullet__padding * 0.5;  
				
				span { 					
					background-color: @swatches[almostWhite];
				}
			}
		}
		.vueperSlide {
			&__content {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				background-color: fade( cyan, 30 );
				height: 100%;
				
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 6rem;
			}
		}
		
		&__image {
			position: absolute !important;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		
		// das ist der bu holder: 
		// da das layout ein festes seitenverhältnis verlangt und die bus
		// davon allerdings ausgenommen sind, habe ich diese einfach in einen
		// container darunter gesetzt
		&__caption {
			//background-color: fade( yellow, 20 );
			
			//white-space: pre;
			padding: 1em;
			
			&:empty { display: none; }
			
			a {
				transition: opacity 0.1s ease;
				color: inherit;
				opacity: 0.5;
			}
			a:hover {
				opacity: 1;
			}
		}
	}
</style>
