<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
		font	String		default "serif"
		text	String
	
	Beispiel Code:
		<GmText
			font="sans"
			text="Lorem ipsum"
		></GmText>
	
	2019-06-08	init

-->

<template>
	<div class="GmText contentModule">
		<div class="contentModule__inner grid grid--GmText">
			<!-- <div class="color XXcolor--almostWhite" :class="textClasses" v-html="text"></div> -->
			<div :class="textClasses" v-html="text"></div>	
		</div>	
	</div>	
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmText',
		components: {},
		mixins: [],
		props: {
			text: [String],
			font: {
				type: String,
				default: 'serif',
			},
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			textClasses(){
				let classes = [
					'text',
					'text--center', 
					'font',
				]
				
				if( this.font == 'serif' ){
					classes.push('font--serif')
					classes.push('font--sizeBig')
				}
				if( this.font == 'sans' ){
					classes.push('font--sans')
					classes.push('font--sizeMediumSans2')
				}
				if( this.font == 'mono small' ){
					classes.push('font--mono')
				}
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";

	// layout: alle deklarations bzgl. position und dimension	
	.GmText {}
	
	// appearance: alle deklarations außer position und dimension
	.GmText {
		a {
			transition: opacity 0.1s ease;
			opacity: 0.5;
			color: inherit;
		}
		a:hover {
			opacity: 1;
		}	
	}
</style>
