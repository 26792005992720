<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmTint></GmTint>
	
	2019-10-05	init

-->

<template>
	<div class="GmTint"></div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmTint',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				doIt : false,
				debugLog : false,
				root : document.documentElement,
				lastViewName : '',
				durationTint : '0.2s',				
			}
		},
		watch: {},
		computed: {},
		methods: {
			onEventBeforeMount( payload ){
				if( this.debugLog ){
					console.log('');
					console.log('onEventBeforeMount');
					console.log('	payload', payload);
				}
			
				this.setCssVar( { appLoadingSpinner__opacity : 1 } )
			},		
			onEventMounted( payload ){},
			onEventReady( payload ){				
				if( this.debugLog ){
					console.log('');
					console.log('onEventReady');
					console.log('	payload', payload);
				}
				
				this.setCssVar( { appNav__background : payload.viewBackgroundColor } )	
				this.setCssVar( { appNav__color : payload.viewTextColor } )									
				this.setCssVar( { appBody__background : payload.viewBackgroundColor } )	
				this.setCssVar( { appBody__color : payload.viewTextColor } )									
				
				this.setCssVar( { appLoadingSpinner__opacity : 0 } )
			},
			onEventBeforeDestroy( payload ){},			
			setCssVar( pair ){
				const root     = this.root
				const key      = Object.keys(pair)[0]
				const value    = Object.values(pair)[0]
				const property = this._.startsWith( value, '--' ) ? getComputedStyle(document.documentElement).getPropertyValue( value ) : value;
				const debugLog = false
				
				if( this.debugLog || debugLog ){
					console.log('');
					console.log('setCssVar');
					console.log('	key', key);
					console.log('	value', value);
					console.log('	property', property);
				}
				
				root.style.setProperty('--' + key, property);
			}
		},
		created() {},
		mounted() {
			this.setCssVar( { duration__tint : this.durationTint } )

			EventBus.$on('ViewEvent', (payload) => {
				//console.log('')
				//console.log('ViewEvent')
				//console.log('	payload', payload)
				
				if( payload.eventName == 'beforeMount' ){
					this.onEventBeforeMount( payload )
				}
				if( payload.eventName == 'mounted' ){
					this.onEventMounted( payload )
				}
				if( payload.eventName == 'ready' ){
					this.onEventReady( payload )
				}
				if( payload.eventName == 'beforeDestroy' ){
					this.lastViewName = payload.fromComponent
					this.onEventBeforeDestroy( payload )
				}
			})	
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";
	
	.GmTint {}
	
	// vars
	:root {
		--swatch__white              : @swatches[white]; 
		--swatch__almostWhite        : @swatches[almostWhite];
		--swatch__almostBlack        : @swatches[almostBlack];
		--swatch__darkMud            : @swatches[darkMud];
		--swatch__darkGreen          : @swatches[darkGreen];
		--swatch__darkGrey           : @swatches[darkGrey];
		
		--appLoadingSpinner__opacity : 0;
		--duration__tint             : 0s;
	}
	// map vars to properties
	:root {
		--appNav__background       : var(--swatch__darkGrey);
		--appNav__color            : var(--swatch__almostWhite);
		
		--appBody__background      : var(--swatch__darkGrey);
		--appBody__color           : var(--swatch__almostWhite);
	}	
	.app {
		// init transitions
		.app__nav,
		.app__body {
			transition: 
				background-color var(--duration__tint) ease,
				color var(--duration__tint) ease;
		}
		// background and text color
		.app__nav {
			background-color: @swatches[darkGrey]; // fallback if vars are not supported
			color: @swatches[almostWhite]; // fallback if vars are not supported
			
			background-color: var(--appNav__background);
			color: var(--appNav__color);
		}
		// background and text color
		.app__body {
			background-color: @swatches[darkGrey]; // fallback if vars are not supported
			color: @swatches[almostWhite]; // fallback if vars are not supported
			
			background-color: var(--appBody__background);
			color: var(--appBody__color);
		}
		// router view transition opacity 
		.app__bodyInner {
			&.fade-enter-active,
			&.fade-leave-active {
				transition-duration: var(--duration__tint);
				transition-property: opacity;
				transition-timing-function: ease;
			}
			&.fade-enter-active {}
			&.fade-enter,
			&.fade-leave-active {
				opacity: 0;
			}
		}
		// loading spinner opacity
		.app__loadingSpinner {
			opacity: var(--appLoadingSpinner__opacity);
			//opacity: 1 !important;
		}
	}
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
