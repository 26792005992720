<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
		title
		linkText
		linkUrl
	
	Beispiel Code:
		<GmHeadline
			title="Was wir machen"
			fontSize="default"
			linkUrl="//www.taz.de"
			linkText="Mehr Referenzen sehen"
		></GmHeadline>
	
	2019-06-08	init

-->

<template>
	<div class="GmHeadline contentModule">
		<!--  <div class="GmHeadline__inner font font--mono color XXcolor--almostWhite">-->			
		<div class="GmHeadline__inner contentModule__inner font font--mono">			
			<a class="GmHeadline__link GmHeadline__link--hidden font font--sizeSmall font--uppercase" 
				:href="linkUrl"
				v-if="hasLink">{{linkText}}</a>
			<span class="GmHeadline__lineWrapper GmHeadline__lineWrapper--hidden">
				<span class="GmHeadline__line" v-if="hasLink"></span>
			</span>
			
			<h2 class="GmHeadline__title" :class="titleClasses" v-html="title"></h2>
			
			<span class="GmHeadline__lineWrapper">
				<span class="GmHeadline__line" v-if="hasLink"></span>
			</span>
			<a class="GmHeadline__link font font--sizeSmall font--bold font--uppercase" 
				:href="linkUrl"
				v-if="hasLink">{{linkText}}</a>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmHeadline',
		components: {},
		mixins: [],
		props: {
			title: [String],
			fontSize: [String],
			linkText: [String],
			linkUrl: [String],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			hasLink(){
				return this.linkText && this.linkUrl ? true : false
			},
			titleClasses(){
				let classes = []				
				if( this.fontSize === 'small' ){
					classes.push('font--sizeSmall')
					classes.push('font--bold')
					classes.push('font--uppercase')
				}else{
					classes.push('font--sizeDefault')
				}
				if( this.hasLink && this.$mq === 'sm' ) classes.push('font--uppercase')
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	
	@textHPadding: 1rem;

	/*
	[showBorders3] .GmHeadline {
		&__inner > * { outline: 1px solid red; }
	}
	*/

	// layout: alle deklarations bzgl. position und dimension
	.GmHeadline {		
		&__inner {
			display: flex;
		}
		
		&__lineWrapper {
			position: relative;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&__lineWrapper--hidden {
			visibility: hidden;
		}
		&__line {
			display: block;
			border: 1px solid currentColor;
			opacity: 0.2;
		}
		
		&__title {
			padding-left: @textHPadding;
			padding-right: @textHPadding;
		}
		
		&__link {
			display: flex;
			padding-left: @textHPadding;
			padding-top: 0.1em;
			align-items: center;
		}
		&__link--hidden {
			visibility: hidden;
		}

		// leichtes nach unten setzen des links
		// damit die grundlinie mit dem title gehalten wird
		@media @mediaQuery__md {
			&__link {
				padding-top: 0.1em;
			}
		}
		
		// laut soll der link auf der rechten seite
		// etwas eingerückt sein
		@media @mediaQuery__md {
			/*
			&__inner {
				padding-left: 4rem; 
				padding-right: 4rem;
			}
			*/
			&__title {
				padding-left: @textHPadding*0.5;
				padding-right: @textHPadding*0.5;
			}
			&__link {
				padding-left: @textHPadding*0.5;
			}
		}
		
		// bei sm: die headline wird zweizeilig
		@media @mediaQuery__sm {
			&__inner {
				display: block;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
			}
			&__link--hidden,
			&__lineWrapper {
				display: none;
			}
			&__link:last-child {
				padding: 0;
				//padding-top: 0.25em;
				display: block;
			}
		}
	}
	
	// appearance: alle deklarations außer position und dimension
	.GmHeadline {
		a {
			transition: opacity 0.1s ease;
			color: inherit;
			text-decoration: none;
			opacity: 0.5;
		}
		a:hover {
			opacity: 1;
		}
	}	
</style>
