<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<GmMainHeader></GmMainHeader>

	2019-06-08	init

-->

<template>
	<div class="GmMainHeader contentModule flex flex--center" :class="elmClasses">

		<!-- title bar -->
		<div class="GmMainHeader__titleBar flex flex--center">

			<div class="GmMainHeader__titles grid grid--GmMainHeader">
				<div class="flex flex--middle"></div>
				<!--
				<strong class="GmMainHeader__companyTitle flex flex--middle">
					<router-link
					:to="{ name: 'HomeView', params: {} }"
					class="font font--sizeSmall font--mono XXXcolor XXXcolor--almostWhite"
					:class="{'hover' : pageTitleIsHovered}"
					>INITIATIVE <br v-if="$mq === 'sm' || $mq === 'xs'"/>FÜR</router-link>
				</strong>
				-->
				<h1 class="GmMainHeader__pageTitle flex flex--center flex--middle">
					<router-link
						:to="{ name: 'HomeView', params: {} }"
						class="font font--sizeMainTitle font--serifText font--bold text text--center color color--almostWhite"
						@mouseleave.native="pageTitleIsHovered = false"
						@mouseenter.native="pageTitleIsHovered = true"
					>GENUSS<br v-if="$mq === 'sm' || $mq === 'xs'"/>MARKEN</router-link>
				</h1>
			</div>

			<a	class="GmMainHeader__menuOpenBtn flex flex--middle"
				@click="menuIsActive = !menuIsActive" ref="menuOpenBtn">
				<span class="XXXcolor XXXcolor--almostWhite">
					<!-- Menü  -->
					<span></span>
				</span>
			</a>
			<a	class="GmMainHeader__fixedMenuOpenBtn" :style="fixedMenuBtnStyles"
				@click="menuIsActive = !menuIsActive" ref="fixedMenuOpenBtn">
				<span class="XXXcolor XXXcolor--almostWhite">
					<!-- Menü  -->
					<span></span>
				</span>
			</a>

		</div>

		<!-- slide out menu -->
		<nav class="GmMainHeader__menu bgColor flex flex--center bgColor--almostWhite color color--almostBlack" :class="menuClasses" v-if="app.acfOptions">

			<div class="GmMainHeader__menuInner">

				<div class="GmMainHeader__menuTitleBar flex flex--center" v-if="$mq === 'md' || $mq === 'sm' || $mq === 'xs'">

					<div class="GmMainHeader__titles grid grid--GmMainHeader">
						<div class="flex flex--middle"></div>
						<!--
						<strong class="GmMainHeader__companyTitle flex flex--middle">
							<router-link
								:to="{ name: 'HomeView', params: {} }"
								class="font font--sizeSmall font--mono XXXcolor XXXcolor--almostWhite"
								:class="{'hover' : pageTitleIsHovered}"
							>INITIATIVE <br v-if="$mq === 'sm' || $mq === 'xs'"/>FÜR</router-link>
						</strong>
						-->
						<h1 class="GmMainHeader__pageTitle flex flex--center flex--middle">
							<router-link
								:to="{ name: 'HomeView', params: {} }"
								class="font font--sizeMainTitle font--serifText font--bold text text--center color color--almostWhite"
								@mouseleave.native="pageTitleIsHovered = false"
								@mouseenter.native="pageTitleIsHovered = true"
							>GENUSS<br v-if="$mq === 'sm' || $mq === 'xs'"/>MARKEN</router-link>
						</h1>
					</div>

				</div>

				<div class="GmMainHeader__menuCloseBtn flex flex--middle">
					<a @click="menuIsActive = !menuIsActive">
						<span></span>
						<span></span>
					</a>
				</div>

				<ul class="GmMainHeader__menuList font font--sizeHuge font--serif reset reset--list">
					<li class="GmMainHeader__menuItem"
						v-for="(item, index) in app.acfOptions.mainMenu"
						:style="{'--animation-order' : index}"
						:key="index">
						<a class="GmMainHeader__menuLink" :href="urlPathname( item.link )">
							{{item.title}}
						</a>
					</li>
				</ul>

			</div>

		</nav>

		<!-- darken overlay -->
		<div class="GmMainHeader__overlay" :class="overlayClasses" @click="menuIsActive = false"></div>

	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import MhHelper from '@/components/MhHelper/MhHelper.js'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmMainHeader',
		components: {},
		mixins: [
			MhHelper,
		],
		props: {
			brandHasNoOpacity: {
				type: [Boolean],
				default: true,
			},
		},
		data() {
			return {
				menuIsActive : false,
				pageTitleIsHovered : false,
				menuBtnProperties : { // wird via doPosFixedMenuOpenBtn() gesetzt
					left  : null,
					height: null,
					width : null,
				},
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []

				if( this.menuIsActive ) classes.push('GmMainHeader--menuIsActive')
				if( this.brandHasNoOpacity ) classes.push('GmMainHeader--brandHasNoOpacity')

				return classes
			},
			menuClasses(){
				let classes = []

				if( this.menuIsActive ) classes.push('GmMainHeader__menu--isActive')

				return classes
			},
			overlayClasses(){
				let classes = []

				if( this.menuIsActive ) classes.push('GmMainHeader__overlay--isActive')

				return classes
			},
			fixedMenuBtnStyles(){
				return this.menuBtnProperties
			},
			app(){
				return this.$root.$children[0]
			},
		},
		methods: {
			windowResizeHandler(){
				this.doPosFixedMenuOpenBtn()
			},
			doPosFixedMenuOpenBtn(){
				const doLog             = false
				const realBtnElm        = this.$refs.menuOpenBtn
				const realBtnElmRect    = realBtnElm.getClientRects()[0]
				const fixedBtnElmBtnElm = this.$refs.fixedMenuOpenBtn

				this.menuBtnProperties.left   = realBtnElmRect.left + 'px'
				this.menuBtnProperties.height = realBtnElmRect.height + 'px'
				this.menuBtnProperties.width  = realBtnElmRect.width + 'px'

				//console.clear()
				if( doLog ){
					console.group('doPosFixedMenuOpenBtn()')
					console.log('realBtnElm:', realBtnElm)
					console.log('realBtnElmRect:', realBtnElmRect)
					console.log('realBtnElmRect.left:', realBtnElmRect.left)
					console.log('realBtnElmRect.width:', realBtnElmRect.width)
					console.log('realBtnElmRect.height:', realBtnElmRect.height)
					console.log('fixedBtnElmBtnElm:', fixedBtnElmBtnElm)
					console.groupEnd()
				}
			},
		},
		created() {
			window.addEventListener('resize', this.windowResizeHandler)
		},
		mounted() {
			this.$parent.includesGmMainHeader = true

			// die umgebenede section wird beim nach oben scrollen sticky gesetzt
			// um das menu ein- und auszublenden
			EventBus.$on('XXXMhView__scrolled', ( payload ) => {
				const scrollTop = payload.scrollTop
				const direction = payload.direction
				const delta     = Math.abs( payload.delta )

				//console.log( payload )

				// blende menu ein wenn:
				// - nach mindestens 30px oben gescrollt wurde
				// - die mindestens bereits 150px gescrollt wurde
				// - das menu nicht bereits sichtbar ist
				if( direction == 'up' && scrollTop > 150 && delta > 30 && !this.$parent.isSticky){
					/*
					this.$parent.$el.style.transition = "none"

					setTimeout(()=>{
						this.$parent.isOffscreen = true
						this.$parent.isSticky = true
					}, 10)
					setTimeout(()=>{
						this.$parent.$el.style.transition = "all 0.1s ease"
					}, 20)
					setTimeout(()=>{
						this.$parent.isOffscreen = false
					}, 30)
					setTimeout(()=>{
						this.$parent.$el.style.transition = ""
					}, 150)
					*/
					this.$parent.isSticky = true
				}
				// unsticky menu wenn ganz nach oben gescrollt wurde
				if( direction == 'up' && scrollTop < 50 ){
					/*
					this.$parent.isSticky = false
					this.$parent.isOffscreen = false
					*/
					this.$parent.isSticky = false
				}
				// blende menu aus wenn:
				// - es sichtbar (sticky) ist
				if( direction == 'down' && scrollTop > 150 && this.$parent.isSticky){
					/*
					this.$parent.$el.style.transition = "all 0.1s ease"

					setTimeout(()=>{
						this.$parent.isOffscreen = true
					}, 10)
					setTimeout(()=>{
						this.$parent.isSticky = false
					}, 150)
					setTimeout(()=>{
						this.$parent.isOffscreen = false
						this.$parent.$el.style.transition = ""
					}, 150)
					*/
					this.$parent.isSticky = false
				}

			})

			this.doPosFixedMenuOpenBtn()
		},
		beforeDestroy(){
			window.removeEventListener('resize', this.windowResizeHandler)
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	@menu__transitionDuration : 0.35s;

	// layout: alle deklarations bzgl. position und dimension
	.GmMainHeader {
		position: relative;
		margin-left: 0;
		margin-right: 0;

		//position: absolute;
		//top: 0; width: 100vw;

		// titleBar
		&__titleBar {
			width: 100vw;
			padding-top: 1.75rem;
			padding-bottom: 1.75rem;

			@media @mediaQuery__md {
				//padding-top: 2.75rem;
				//padding-bottom: 1.75rem;
			}
			@media @mediaQuery__sm {
				padding-top: 0rem;
				padding-bottom: 0rem;
			}
		}
		&__titles {
			max-width: calc( @content_maxWidth - (@contentModule__hMargin * 2) );
			width: 100%;
			z-index: 2; // above the menu
		}
		&__companyTitle {
			a {
				transform: translateY(5px);

				@media @mediaQuery__md {
					transform: translateY(0.13em);
				}
				@media @mediaQuery__sm {
					transform: translateY(-0.06em);
				}
			}
		}
		&__fixedMenuOpenBtn,
		&__menuOpenBtn {
			position: absolute;
			right: 0; top: 0; bottom: 0;

			overflow: hidden; // hidden text
			text-indent: -100em;
			z-index: 3;

			& > span {
				width: 48px;
				height: 16px;
				display: flex;
				flex-direction: column;

				@media @mediaQuery__md {
					width: 44px;
					height: 15px;
				}
				@media @mediaQuery__sm {
					width: 27px;
					height: 10px;
				}
			}
			& > span > span {
				flex-grow: 1;
			}
		}
		&__menuOpenBtn {
			visibility: hidden;
			pointer-events: none;

			[showBorders3] & {
				outline: 1px solid yellow;
				visibility: visible;
				pointer-events: auto;
			}
		}
		&__fixedMenuOpenBtn {
			[showBorders3] & { outline: 1px solid red; }
			position: fixed;
			top: 16px;
			//top: 8px;
			display: flex;
			align-items: center;

			@media @mediaQuery__md {}
			@media @mediaQuery__sm {}
		}

		// menu slide out
		&__menu {
			transition: all @menu__transitionDuration ease;

			position: fixed;
			top: 0; right: 0; //right: var(--scrollbar-width);
			height: 100vh;
			transform: translateX(100%);
			pointer-events: none;
			z-index: 9;	// above the contentModules, below the toggleBtn

			@media @mediaQuery__md {
				transform: translateY(-100%);
				height: auto;
				left: 0;
			}
		}
		&__menuInner {
			padding-right: var(--scrollbar-width);
			width: 100%;
		}
		&__menu--isActive {
			transform: translateX(0%);
			pointer-events: auto;

			@media @mediaQuery__md {
				transform: translateY(0%);
			}
		}
		&__menuTitleBar {
			padding-top: calc( 1.75rem + @section__paddingTop );
			padding-bottom: calc( 1.75rem + @section__paddingBottom );
			//margin-left: @contentModule__hMargin;
			//margin-right: @contentModule__hMargin;

			@media @mediaQuery__sm {
				padding-top: @section__paddingTop;
				padding-bottom: @section__paddingBottom;
				//margin-left: @contentModule__hMarginSm;
				//margin-right: @contentModule__hMarginSm;
			}
		}
		&__menuCloseBtn {
			position: absolute;
			top: 3.5rem; right: 2rem;
			margin-right: var(--scrollbar-width); // beachte die scrollbar breite
			//background-color: fade( red, 10 );

			@btn__height : 2.75rem;

			a {
				position: absolute;
				width: @btn__height;
				height: @btn__height;
				right: 0;
				cursor: pointer;
				z-index: 3;
			}
			a:hover {
				opacity: 0.5 !important;
			}
			span {
				position: absolute; top: 50%;
				background-color: @swatches[almostBlack];
				height: 8px; margin-top: -4px;
				width: 100%;
			}
			span:nth-child(1) {
				transform: rotate(45deg);
			}
			span:nth-child(2) {
				transform: rotate(-45deg);
			}

			@media @mediaQuery__md { // lesser stroke width
				span {
					height: 4px; margin-top: -2px;
				}
			}
			@media @mediaQuery__md {
				top: 3.35rem; right: 2.75rem;
			}
			@media @mediaQuery__sm {
				top: 2.2rem; right: 0.65rem;
			}
		}
		&__menuList { // menuList padding
			@media @mediaQuery__dt {
				padding-top: 1.7rem;
				padding-left: 2rem;
				padding-right: 10rem;
			}
			@media @mediaQuery__md {
				padding-top: 2rem;
				padding-bottom: 7rem;
			}
		}

		// darken overlay
		&__overlay {
			transition: all @menu__transitionDuration ease;

			position: fixed;
			top: 0; left: 0; right: var(--scrollbar-width);
			height: 100vh;
			pointer-events: none;
			z-index: 8;
		}
		&__overlay--isActive {
			// 2019-10-08  sollte wieder raus :(
			//background-color: fade(black, 25);
			pointer-events: auto;
		}
	}
	// appearance: alle deklarations außer position und dimension
	.GmMainHeader {
		&__companyTitle a,
		&__pageTitle a {
			opacity: 0.5;
		}
		&--brandHasNoOpacity &__companyTitle a,
		&--brandHasNoOpacity &__pageTitle a {
			opacity: 1;
		}

		&__fixedMenuOpenBtn span,
		&__menuOpenBtn span {
			background-color: currentColor;
		}
		&__fixedMenuOpenBtn,
		&__menuOpenBtn {
			transition: all 0.1s ease;
			opacity: 0.5;
		}
		&__fixedMenuOpenBtn:hover,
		&__menuOpenBtn:hover  {
			opacity: 1 !important;
		}

		&__menuList { // text-align center
			@media @mediaQuery__md {
				text-align: center;
			}
		}
		&__menuLink {
			color: inherit;
		}
		&__menuLink:hover {
			opacity: 0.5 !important;
		}

		// change titleBar text color
		.color {
			transition: all @menu__transitionDuration ease;
		}
		&--menuIsActive .color {
			@media @mediaQuery__md {
				color: @swatches[almostBlack];
			}
		}
		// change companyTitle opacity
		&--menuIsActive &__companyTitle a {
			@media @mediaQuery__md {
				opacity: 1;
			}
		}

		a {
			color: inherit;
			transition: all 0.1s ease;
			text-decoration: none;
		}
		a.hover, a:hover {
			opacity: 0.5;
			opacity: 1;
		}
	}
	// animation: stack menuItems
	.GmMainHeader {
		&--menuIsActive &__menuItem {
			// from top
			@media @mediaQuery__dt {
				animation-name: animateInFromRight;
				animation-duration: @menu__transitionDuration;
				animation-delay: calc( (var(--animation-order) * 50ms) + 50ms );
				animation-fill-mode: both;
				animation-timing-function: ease-in-out;
			}
			// from right
			@media @mediaQuery__md {
				animation-name: animateInFromTop;
				animation-duration: @menu__transitionDuration;
				animation-delay: calc( (var(--animation-order) * 50ms) + 50ms );
				animation-fill-mode: both;
				animation-timing-function: ease-in-out;
			}
		}
	}

	@keyframes animateInFromTop {
		0% {
			transform: scale(1) translateY(-100%);
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes animateInFromRight {
		0% {
			transform: scale(1) translateX(1em);
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
