<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<GmProjectFooterNav
			firstLinkText="Alle Referenzen"
			firstLinkUrl="http://taz.net"
			secondLinkText="Nächstes Projekt"
			secondLinkUrl="http://taz.net"
			secondLinkTitle="TAZ"
		></GmProjectFooterNav>
	
	2019-06-08	init

-->

<template>
	<div class="GmProjectFooterNav contentModule" :class="elmClasses">
		<div class="GmProjectFooterNav__inner contentModule__inner font font--mono color color--white">
			<router-link 
				class="GmProjectFooterNav__link" 
				:to="firstLinkUrl">
				{{firstLinkText}}
			</router-link>
			<a 	class="GmProjectFooterNav__link"
				:title="secondLinkTitle"
				:href="secondLinkUrl"
				v-if="secondLinkUrl">
				{{secondLinkText}}
			</a>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'GmProjectFooterNav',
		components: {},
		mixins: [],
		props: {
			firstLinkText: [String],
			firstLinkUrl: [String],
			secondLinkText: [String],
			secondLinkTitle: [String],
			secondLinkUrl: [String],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	// layout: alle deklarations bzgl. position und dimension
	.GmProjectFooterNav {
		&__inner {
			display: flex;
			justify-content: space-between;			
		}
	}
	
	// appearance: alle deklarations außer position und dimension
	.GmProjectFooterNav {
		a {
			transition: opacity 0.1s ease;
			color: inherit;
			opacity: 0.5;
		}
		a:hover {
			opacity: 1;
		}
	}
</style>
