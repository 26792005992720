<!-- 

	Diese Componente errechnet die Breite der Scrollbar via
	JavaScript und schreibt sie danach als CSS Variable in :root.
	Abgerufen kann sie werden über: margin-right: var(--scrollbar-width); 
	
	Welche $props gibt es?
		Keine
	
	Beispiel Code:
		<MhGetScrollbarWidth />
	
	2019-06-27	init

-->

<template>
	<div class="MhGetScrollbarWidth" :class="elmClasses"></div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'MhGetScrollbarWidth',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				scrollBarWidth : 0,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {
			measureScrollbarWidth() {
				// Add temporary box to wrapper
				let scrollbox = document.createElement('div');

				// Make box scrollable
				scrollbox.style.overflow = 'scroll';

				// Append box to document
				document.body.appendChild(scrollbox);

				// Measure inner width of box
				this.scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;

				// Remove box
				document.body.removeChild(scrollbox);
			},
		},
		created() {
			this.measureScrollbarWidth()
		},
		mounted() {
			const htmlElm = document.querySelector('html')
			
			htmlElm.style.setProperty('--scrollbar-width', this.scrollBarWidth+'px');
			
			//console.log('scrollBarWidth:', this.scrollBarWidth);
			//console.log('htmlElm:', htmlElm);
		},
	}
</script>

<style lang="less">
	.MhGetScrollbarWidth {}
</style>
