export default {
	"environments" : {
		"dev" : { 										// the name of the environment
			"hostname" : "localhost", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://genussmarken.local", // the url to the wordpress restApi
		},
		"dev2" : { 							// the name of the environment
			"hostname" : "192.168", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://genussmarken.local", // the url to the wordpress restApi
		},
		"dev3" : { 							// the name of the environment
			"hostname" : "genussmarken.local", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://genussmarken.local", // the url to the wordpress restApi
		},
		"stage" : { 							// the name of the environment
			"hostname" : "dev.genussmarken.de", 					// the hostname the app is served from, this detects the environment
			"restBase" : "//dev.genussmarken.de", // the url to the wordpress restApi
		},
		"live" : { 							// the name of the environment
			"hostname" : "genussmarken.de", 					// the hostname the app is served from, this detects the environment
			"restBase" : "//genussmarken.de", // the url to the wordpress restApi
		},
	}
}
